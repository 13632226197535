import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { BasicDialog, Checkbox, Form, SpacerVertical, TextArea, TextField } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { updateProject } from '../../actions'
import SettingsTabs from './SettingsTabs'

const Settings = ({ project, isUpdatingProject, isNarrow }) => {
  const [name, setName] = useState(project.name)
  const [nameError, setNameError] = useState('')
  const [description, setDescription] = useState(project.description)
  const [isPublic, setIsPublic] = useState(!project.private)
  const [rememberAttributes, setRememberAttributes] = useState(project.rememberAttributes)

  const handleSubmit = async () => {
    if (name.trim() === '') {
      setNameError('Du måste ange ett namn')
      return
    }

    if (await updateProject({ name, description, private: !isPublic, rememberAttributes })) {
      onClose()
    }
  }

  const onClose = () => goBack(`/projects/${project.id}`)

  return (
    <BasicDialog
      large={!isNarrow}
      full={isNarrow}
      flexGrowContent={isNarrow}
      disableAutofocus
      title="Inställningar"
      primaryActionText="Spara"
      primaryActionLoading={isUpdatingProject}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <SettingsTabs active="settings" />
      <SpacerVertical />
      <Form onSubmit={handleSubmit}>
        <TextField width="100%" label="Namn" value={name} onInput={setName} errorText={nameError} />
        <SpacerVertical />
        <TextArea width="100%" label="Beskrivning" value={description} onInput={setDescription} />
        <SpacerVertical />
        <Checkbox label="Öppet för alla användare" checked={isPublic} onChange={setIsPublic} />
        <SpacerVertical />
        <Checkbox
          label="Kom ihåg attribut vid ny punkt"
          checked={rememberAttributes}
          onChange={setRememberAttributes}
        />
      </Form>
    </BasicDialog>
  )
}

export default connect('project, isUpdatingProject, isNarrow')(Settings)
