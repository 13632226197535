import { h } from 'preact'
import { connect } from 'unistore/preact'
import { Row } from 'jsxstyle/preact'
import { Avatar, IconButton, MenuIcon } from '@sodra/bongo-ui'

const Header = ({ user, onShowMenu, isNarrow }) => {
  if (isNarrow) {
    return (
      <Row
        alignItems="center"
        padding="10px 20px"
        borderBottom="solid 1px var(--on-surface-lighter)"
      >
        <IconButton flexShrink="0" icon={MenuIcon} onClick={onShowMenu} />
      </Row>
    )
  }

  return (
    <Row alignItems="center" padding="10px 20px" borderBottom="solid 1px var(--on-surface-lighter)">
      <IconButton icon={MenuIcon} onClick={onShowMenu} />
      <Row flex="1" justifyContent="flex-end">
        <Avatar src={user.picture} name={user.name} />
      </Row>
    </Row>
  )
}

export default connect('user, isNarrow')(Header)
