import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { BasicDialog, Checkbox, Form, SpacerVertical, TextField } from '@sodra/bongo-ui'

import { goBack } from '@sodra/prutt'

import { createUser } from '../../actions'

const CreateUser = ({ isCreatingUser, isNarrow, onUserCreated }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)

  const onClose = () => {
    goBack(`/users`)
  }

  const handleSubmit = async () => {
    if (await createUser({ name, email, password, isAdmin })) {
      if (onUserCreated) {
        onUserCreated()
      }
      onClose()
    }
  }

  return (
    <BasicDialog
      full={isNarrow}
      disableAutofocus
      title="Ny användare"
      primaryActionText="Spara"
      primaryActionLoading={isCreatingUser}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <TextField width="100%" label="Namn" value={name} onInput={setName} />
        <SpacerVertical />
        <TextField width="100%" label="Epost" value={email} onInput={setEmail} />
        <SpacerVertical />
        <TextField
          type="password"
          width="100%"
          label="Lösenord"
          value={password}
          onInput={setPassword}
        />
        <SpacerVertical />
        <Checkbox label="Admin" checked={isAdmin} onChange={setIsAdmin} />
      </Form>
    </BasicDialog>
  )
}

export default connect('isCreatingUser, isNarrow')(CreateUser)
