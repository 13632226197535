import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Block, Col, Row } from 'jsxstyle/preact'
import {
  AddIcon,
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  Divider,
  IconButton,
  List,
  ListItem,
  LockIcon,
  SpacerVertical
} from '@sodra/bongo-ui'
import { Route, routeTo, Switch } from '@sodra/prutt'

import { get } from '../../api'
import { setError } from '../../actions'
import { useLocalStorageState } from '../../use-local-storage-state'

import Project from './Project'
import Profile from './Profile'
import CreateProject from './CreateProject'
import Header from './Header'
import LeftMenu from './LeftMenu'
import RequestAccess from './RequestAccess'

const Paginator = ({ offset, limit, total, setOffset }) => {
  const pages = Math.ceil(total / limit)
  const page = Math.floor(offset / limit) + 1
  return (
    <Row alignItems="center" justifyContent="flex-end">
      <IconButton
        icon={ChevronLeftIcon}
        disabled={page === 1}
        onClick={() => setOffset(offset - limit)}
      />
      Sida {page} av {pages}
      <IconButton
        icon={ChevronRightIcon}
        disabled={page === pages}
        onClick={() => setOffset(offset + limit)}
      />
    </Row>
  )
}

const Projects = ({ user }) => {
  const [query, setQuery] = useLocalStorageState('fotoapp:query', '')
  const [offset, setOffset] = useLocalStorageState('fotoapp:offset', 0)
  const limit = 10

  const [menuVisible, setMenuVisible] = useState(false)
  const [projects, setProjects] = useState([])
  const [total, setTotal] = useState(0)
  const [isFetchingProjects, setIsFetchingProjects] = useState(false)

  useEffect(() => {
    setOffset(0)
  }, [query])

  useEffect(() => {
    let cancelled = false
    setIsFetchingProjects(true)
    get(`/projects`, { query, offset, limit })
      .then((res) => {
        if (!cancelled) {
          setTotal(res.meta.total)
          setProjects(res.data)
        }
      })
      .catch((err) => {
        if (!cancelled) {
          setError(err)
        }
      })
      .finally(() => {
        if (!cancelled) {
          setIsFetchingProjects(false)
        }
      })
    return () => (cancelled = true)
  }, [query, offset, limit])

  const handleClick = (project) => {
    const hasAccess =
      user.isAdmin || !project.private || project.members.some((member) => member.id === user.id)
    if (hasAccess) {
      routeTo(`/projects/${project.id}`)
    } else {
      routeTo(`/projects/request-access/${project.id}`)
    }
  }

  return (
    <Col
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      overflow="auto"
      background="var(--surface)"
    >
      <Header onShowMenu={() => setMenuVisible(true)} query={query} setQuery={setQuery} />
      <Block flex="1" overflow="auto">
        <SpacerVertical />
        <Block width="900px" maxWidth="calc(100% - 40px)" margin="auto">
          <Row alignItems="center">
            <Block>Projekt</Block>
            <Block flex="1" />
            <Button small icon={AddIcon} onClick={() => routeTo(`/projects/create-project`)}>
              Nytt projekt
            </Button>
          </Row>
          <SpacerVertical small />
          <Divider />
          <SpacerVertical />
          <List>
            {projects.map((project) => {
              const hasAccess =
                user.isAdmin ||
                !project.private ||
                project.members.some((member) => member.id === user.id)

              return (
                <ListItem
                  text={project.name}
                  secondaryText={project.description}
                  onClick={() => handleClick(project)}
                  actionIcon={hasAccess ? null : LockIcon}
                  actionIconToolTip="Du har inte access till projektet"
                  metaText={project.user ? project.user.name : 'Okänd'}
                />
              )
            })}
          </List>
          {total > limit && (
            <Fragment>
              <SpacerVertical />
              <Divider />
              <SpacerVertical />
              <Paginator offset={offset} limit={limit} total={total} setOffset={setOffset} />
            </Fragment>
          )}
          <SpacerVertical />
          <Switch>
            <Route
              path="/projects/request-access/:projectId"
              render={({ projectId }) => {
                const project = projects.find((p) => p.id === projectId)
                if (project) {
                  return <RequestAccess project={project} />
                }
              }}
            />
            <Route path="/projects/create-project" component={CreateProject} />
            <Route path="/projects/profile" component={Profile} />
            <Route path="/projects/:projectId" component={Project} />
          </Switch>
        </Block>
      </Block>
      {menuVisible && <LeftMenu onClose={() => setMenuVisible(false)} />}
    </Col>
  )
}

export default connect('user')(Projects)
