import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Block, Row } from 'jsxstyle/preact'
import {
  Avatar,
  List,
  ListItem,
  SettingsIcon,
  LogoutIcon,
  Divider,
  SpacerVertical,
  ImageIcon,
  GroupIcon
} from '@sodra/bongo-ui'

import logo from 'url:../../logo.svg'
import { logout } from '../../actions'
import { routeTo } from '@sodra/prutt'

const LeftMenu = ({ user, onClose, isNarrow }) => {
  const [showing, setShowing] = useState(false)

  useEffect(() => {
    setShowing(true)
  }, [])

  const hideMenu = () => {
    setShowing(false)
    setTimeout(onClose, 300)
  }

  return (
    <Block position="absolute" top="0" left="0" bottom="0" right="0" zIndex="100">
      <Block
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        background="rgba(0,0,0,0.3)"
        opacity={showing ? 1 : 0}
        transition="opacity ease-in-out 0.3s"
        props={{ onClick: hideMenu }}
      />
      <Block
        position="absolute"
        top="0"
        left={showing ? 0 : '-240px'}
        bottom="0"
        width="240px"
        background="var(--surface)"
        boxShadow="0 3px 12px var(--box-shadow-color)"
        transition="left ease-in-out 0.3s"
        props={{ onClick: hideMenu }}
      >
        <Row alignItems="center" height="78px" padding="0 20px">
          {!isNarrow && <img src={logo} width="40" />}
          {isNarrow && <Avatar picture={user.picture} name={user.name} />}
        </Row>
        <Divider />
        <SpacerVertical />
        <List>
          <ListItem icon={ImageIcon} text="Projekt" onClick={() => routeTo('/projects')} />
          {user.isAdmin && <ListItem icon={GroupIcon} text="Användare" />}
          <ListItem
            icon={SettingsIcon}
            text="Inställningar"
            onClick={() => routeTo('/projects/profile')}
          />
          <ListItem icon={LogoutIcon} text="Logga ut" onClick={logout} />
        </List>
      </Block>
    </Block>
  )
}

export default connect('user, isNarrow')(LeftMenu)
