import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { BasicDialog, Checkbox, Form, SpacerVertical, TextArea, TextField } from '@sodra/bongo-ui'
import { goBack, routeTo } from '@sodra/prutt'

import { cloneProject } from '../../actions'

const Clone = ({ project, isCloningProject, isNarrow }) => {
  const [name, setName] = useState(project.name + ' Kopia')
  const [nameError, setNameError] = useState('')
  const [description, setDescription] = useState(project.description)
  const [isPublic, setIsPublic] = useState(!project.private)
  const [copyAttributes, setCopyAttributes] = useState(true)
  const [copyMembers, setCopyMembers] = useState(true)

  const handleSubmit = async () => {
    if (name.trim() === '') {
      setNameError('Du måste ange ett namn')
      return
    }

    const project2 = await cloneProject({
      name,
      description,
      private: !isPublic,
      copyAttributes,
      copyMembers
    })
    if (project2) {
      routeTo(`/projects/${project2.id}`, true)
    }
  }

  const onClose = () => goBack(`/projects/${project.id}`)

  return (
    <BasicDialog
      full={isNarrow}
      flexGrowContent={isNarrow}
      disableAutofocus
      title="Kopiera projekt"
      primaryActionText="Spara"
      primaryActionLoading={isCloningProject}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <TextField width="100%" label="Namn" value={name} onInput={setName} errorText={nameError} />
        <SpacerVertical />
        <TextArea width="100%" label="Beskrivning" value={description} onInput={setDescription} />
        <SpacerVertical />
        <Checkbox label="Öppet för alla användare" checked={isPublic} onChange={setIsPublic} />
        <SpacerVertical tiny />
        <Checkbox label="Kopiera attribut" checked={copyAttributes} onChange={setCopyAttributes} />
        <SpacerVertical tiny />
        <Checkbox label="Kopiera medlemmar" checked={copyMembers} onChange={setCopyMembers} />
      </Form>
    </BasicDialog>
  )
}

export default connect('project, isCloningProject, isNarrow')(Clone)
