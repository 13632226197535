import { store } from '../store'
import { del, get, patch, post, put } from '../api'

export const fetchProjects = async () => {
  try {
    store.setState({ isFetchingProjects: true })
    const { data: projects } = await get('/projects')
    store.setState({ projects, isFetchingProjects: false })
    return projects
  } catch (error) {
    store.setState({ error, isFetchingProjects: false })
  }
}

export const fetchProject = async (projectId) => {
  try {
    store.setState({ isFetchingProject: true })
    const { data: project } = await get(`/projects/${projectId}`)
    store.setState({ project, isFetchingProject: false })
    return project
  } catch (error) {
    store.setState({ error, isFetchingProject: false })
  }
}

export const createProject = async (params) => {
  try {
    store.setState({ isCreatingProject: true })
    const { data: project } = await post(`/projects`, params)
    store.setState({ project, isCreatingProject: false })
    return project
  } catch (error) {
    store.setState({ error, isCreatingProject: false })
  }
}

export const cloneProject = async (params) => {
  try {
    store.setState({ isCloningProject: true })
    const projectId = store.getState().project.id
    const { data: project2 } = await post(`/projects/${projectId}/clone`, params)
    store.setState({ isCloningProject: false })
    return project2
  } catch (error) {
    store.setState({ error, isCloningProject: false })
  }
}

export const updateProject = async (params) => {
  try {
    store.setState({ isUpdatingProject: true })
    const projectId = store.getState().project.id
    const { data: project } = await patch(`/projects/${projectId}`, params)
    store.setState({ project, isUpdatingProject: false })
    return project
  } catch (error) {
    store.setState({ error, isUpdatingProject: false })
  }
}

export const deleteProject = async (projectId) => {
  try {
    store.setState({ isDeletingProject: true })
    await del(`/projects/${projectId}`)
    store.setState({ isDeletingProject: false })
    return true
  } catch (error) {
    store.setState({ isDeletingProject: false })
  }
}

export const createAttribute = async (params) => {
  try {
    store.setState({ isUpdatingProject: true })
    const projectId = store.getState().project.id
    const { data: project } = await post(`/projects/${projectId}/attributes/`, params)
    store.setState({ project, isUpdatingProject: false })
    return project
  } catch (error) {
    store.setState({ error, isUpdatingProject: false })
  }
}

export const updateAttribute = async (attributeId, params) => {
  try {
    store.setState({ isUpdatingProject: true })
    const projectId = store.getState().project.id
    const { data: project } = await patch(
      `/projects/${projectId}/attributes/${attributeId}`,
      params
    )
    store.setState({ project, isUpdatingProject: false })
    return project
  } catch (error) {
    store.setState({ error, isUpdatingProject: false })
  }
}

export const deleteAttribute = async (attributeId) => {
  try {
    store.setState({ isUpdatingProject: true })
    const projectId = store.getState().project.id
    const { data: project } = await del(`/projects/${projectId}/attributes/${attributeId}`)
    store.setState({ project, isUpdatingProject: false })
    return project
  } catch (error) {
    store.setState({ error, isUpdatingProject: false })
  }
}

export const createLayer = async (params) => {
  try {
    store.setState({ isUpdatingProject: true })
    const projectId = store.getState().project.id
    const { data: project } = await post(`/projects/${projectId}/layers`, params)
    store.setState({ project, isUpdatingProject: false })
    return project
  } catch (error) {
    store.setState({ error, isUpdatingProject: false })
  }
}

export const deleteLayer = async (layerId) => {
  try {
    store.setState({ isUpdatingProject: true })
    const projectId = store.getState().project.id
    const { data: project } = await del(`/projects/${projectId}/layers/${layerId}`)
    store.setState({ project, isUpdatingProject: false })
    return project
  } catch (error) {
    store.setState({ error, isUpdatingProject: false })
  }
}

export const createInvitation = async (email) => {
  try {
    store.setState({ isCreatingInvitation: true })
    const projectId = store.getState().project.id
    const { data: project } = await post(`/projects/${projectId}/invitations`, { email })
    store.setState({ project, isCreatingInvitation: false })
    return project
  } catch (error) {
    store.setState({ error, isCreatingInvitation: false })
  }
}

export const createRequest = async (projectId) => {
  try {
    store.setState({ isCreatingRequest: true })
    const userId = store.getState().user.id
    const { data: project } = await put(`/projects/${projectId}/requests/${userId}`)
    store.setState({ project, isCreatingRequest: false })
    return project
  } catch (error) {
    store.setState({ error, isCreatingRequest: false })
  }
}

export const addMember = async (userId) => {
  try {
    store.setState({ isAddingMember: true })
    const projectId = store.getState().project.id
    const { data: project } = await put(`/projects/${projectId}/members/${userId}`)
    store.setState({ project, isAddingMember: false })
    return project
  } catch (error) {
    store.setState({ error, isAddingMember: false })
  }
}

export const deleteMember = async (userId) => {
  try {
    store.setState({ isDeletingMember: true })
    const projectId = store.getState().project.id
    const { data: project } = await del(`/projects/${projectId}/members/${userId}`)
    store.setState({ project, isDeletingMember: false })
    return project
  } catch (error) {
    store.setState({ error, isDeletingMember: false })
  }
}

export const deleteInvitation = async (invitationId) => {
  try {
    store.setState({ isDeletingInvitation: true })
    const projectId = store.getState().project.id
    const { data: project } = await del(`/projects/${projectId}/invitations/${invitationId}`)
    store.setState({ project, isDeletingInvitation: false })
    return project
  } catch (error) {
    store.setState({ error, isDeletingInvitation: false })
  }
}

export const deleteRequest = async (userId) => {
  try {
    store.setState({ isDeletingRequest: true })
    const projectId = store.getState().project.id
    const { data: project } = await del(`/projects/${projectId}/requests/${userId}`)
    store.setState({ project, isDeletingRequest: false })
    return project
  } catch (error) {
    store.setState({ error, isDeletingRequest: false })
  }
}
