import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'

import { useMap } from '../use-map'
import { connect } from 'unistore/preact'

const PointMap = ({ project, point, ...params }) => {
  const [mapRef, map] = useMap(project, {
    center: [point.lng, point.lat],
    zoom: point.zoom,
    layers: project.layers
  })

  const { lat, lng, zoom, name } = point

  useEffect(() => {
    if (map) {
      const marker = new mapboxgl.Marker({
        color: '#9C1C58'
      })
        .setLngLat([lng, lat])
        .setPopup(new mapboxgl.Popup({ closeButton: false }).setText(point.name))
        .addTo(map)
      map.setCenter([lng, lat])
      map.setZoom(zoom)
      return () => marker.remove()
    }
  }, [map, lat, lng, zoom, name])

  return <Block props={{ ref: mapRef }} background="var(--surface-alternative)" {...params} />
}

export default connect('project')(PointMap)
