import { useState, useEffect, useCallback } from 'preact/hooks'
//import { MapboxStyleSwitcherControl } from 'mapbox-gl-style-switcher'

//import 'mapbox-gl-style-switcher/styles.css'
import './style-switcher.css'

import { useLocalStorageState } from '../../use-local-storage-state'

import { StyleSwitcher } from './style-switcher'
import { off, on } from '../../events'
import { setUserLocation, clearUserLocation } from '../../actions'

export const useMap = (project, options = {}) => {
  const [container, setContainer] = useState()
  const [styleUri, setStyleUri] = useLocalStorageState(
    `fotoapp:${project.id}:map:styleUri`,
    'mapbox://styles/mapbox/outdoors-v11'
  )
  const [visibleLayers, setVisibleLayers] = useLocalStorageState(
    `fotoapp:${project.id}:visibleLayers`,
    []
  )

  const ref = useCallback((node) => {
    setContainer(node)
  }, [])

  const [map, setMap] = useState()

  const handleVisibleLayersChanged = (visibleLayers) => setVisibleLayers(visibleLayers)

  const handleMapStyleChanged = (style) => setStyleUri(style.uri)

  useEffect(() => {
    let timeout
    const handleResize = () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        if (map) {
          map.resize()
        }
      }, 50)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [map])

  useEffect(() => {
    if (container) {
      console.log('init map')
      const map = new mapboxgl.Map({
        attributionControl: false,
        style: styleUri,
        ...options,
        container
      })

      const styleSwitcher = new StyleSwitcher(options.layers, styleUri, visibleLayers)
      map.addControl(styleSwitcher)

      on('visibleLayersChanged', handleVisibleLayersChanged)
      on('mapStyleChanged', handleMapStyleChanged)

      if (options.geoLocation) {
        const geolocation = new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          fitBoundsOptions: {
            maxZoom: 18
          },
          trackUserLocation: true
        })

        if (options.setUserLocation) {
          geolocation.on('geolocate', (e) => {
            const lat = e.coords.latitude
            const lng = e.coords.longitude
            setUserLocation({ lng, lat })
          })

          geolocation.on('trackuserlocationend', () => {
            if (geolocation._watchState === 'OFF') {
              clearUserLocation()
            }
          })
        }

        map.addControl(geolocation)
      }

      map.addControl(new mapboxgl.NavigationControl())

      if (options.geoJsonUrl) {
        map.on('style.load', function () {
          const mapStyle = map.getStyle()

          let pointColor = '#C25487'
          let selectedPointColor = '#FFB03A'
          let strokeColor = 'white'
          if (mapStyle.name === 'Mapbox Satellite') {
            pointColor = '#00FEFF'
            selectedPointColor = '#FFB03A'
            strokeColor = 'black'
          }

          map.addSource('points', {
            type: 'geojson',
            data: options.geoJsonUrl,
            generateId: true
          })
          map.addLayer({
            id: 'points',
            type: 'circle',
            source: 'points',
            paint: {
              // make circles larger as the user zooms from z10 to z22
              'circle-radius': {
                base: 3,
                stops: [
                  [10, 3],
                  [12, 5],
                  [17, 8]
                ]
              },
              'circle-opacity': [
                'case',
                ['==', ['get', 'id'], options.currentPointId || null],
                0.1,
                1
              ],
              'circle-color': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                selectedPointColor,
                pointColor
              ],
              'circle-stroke-width': 1.5,
              'circle-stroke-color': strokeColor
            }
          })
        })
      }
      setMap(map)
      return () => {
        console.log('remove map')
        off('visibleLayersChanged', handleVisibleLayersChanged)
        off('mapStyleChanged', handleMapStyleChanged)
        map.remove()
      }
    }
  }, [container])

  return [ref, map]
}
