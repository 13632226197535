import { h } from 'preact'
import { connect } from 'unistore/preact'
import { TabItem, Tabs } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

const SettingsTab = ({ project, active }) => {
  return (
    <Tabs>
      <TabItem
        text="Attribut"
        active={active === 'attributes'}
        onClick={() => routeTo(`/projects/${project.id}/attributes`, true)}
      />
      <TabItem
        text="Kartlager"
        active={active === 'layers'}
        onClick={() => routeTo(`/projects/${project.id}/layers`, true)}
      />
      <TabItem
        text="Medlemmar"
        active={active === 'members'}
        onClick={() => routeTo(`/projects/${project.id}/members`, true)}
      />
      <TabItem
        text="Övrigt"
        active={active === 'settings'}
        onClick={() => routeTo(`/projects/${project.id}/settings`, true)}
      />
    </Tabs>
  )
}

export default connect('project')(SettingsTab)
