import shpjs from 'shpjs'

const readAsArrayBuffer = async (file) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.readAsArrayBuffer(file)
  })

const readAsText = async (file) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.readAsText(file)
  })

export const readShapeAsGeoJson = async (shpFile, dbfFile, prjFile) => {
  const shpBuffer = await readAsArrayBuffer(shpFile)
  const dbfBuffer = await readAsArrayBuffer(dbfFile)
  const prjStr = prjFile ? await readAsText(prjFile) : undefined
  return shpjs.combine([shpjs.parseShp(shpBuffer, prjStr), shpjs.parseDbf(dbfBuffer)])
}
