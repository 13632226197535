import { Fragment, h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import {
  AddIcon,
  BasicDialog,
  Button,
  Checkbox,
  SpacerVertical,
  TextArea,
  TextField
} from '@sodra/bongo-ui'
import { goBack, routeTo } from '@sodra/prutt'
import { connect } from 'unistore/preact'
import { Block } from 'jsxstyle/preact'

import { createPoint, justUploadPhoto } from '../../../actions'

import SelectLocation from '../SelectLocation'
import BoxContainer from '../BoxContainer'
import { useLocalStorageState } from '../../../use-local-storage-state'

const PhotoBox = ({ uri, width, margin, onClick }) => {
  const src = `${process.env.CROPPER_URL}?url=${uri}&width=200&height=200&fit=cover`
  return (
    <Block
      position="relative"
      width={`${width - margin * 2}px`}
      height={`${width - margin * 2}px`}
      margin={`${margin}px`}
      backgroundColor="black"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundImage={`url(${src})`}
      borderRadius="3px"
      padding="10px"
      cursor="pointer"
      props={{ onClick }}
    />
  )
}

const NewPointWizard = ({
  project,
  isCreatingPoint,
  isUploadingPhoto,
  isNarrow,
  lng: startLng = 0,
  lat: startLat = 0,
  zoom: startZoom = 0
}) => {
  const fileInput = useRef()
  const [section, setSection] = useState('location')
  const [lng, setLng] = useState(startLng)
  const [lat, setLat] = useState(startLat)
  const [zoom, setZoom] = useState(startZoom)
  const [attributes, setAttributes] = useLocalStorageState('create-point:attributes', [])
  const [photos, setPhotos] = useState([])

  useEffect(() => {
    if (!project.rememberAttributes) {
      setAttributes([])
    }
  }, [project])

  const setAttribute = (id, value) => {
    setAttributes((attributes) => {
      if (attributes.some((a) => a.id === id)) {
        return attributes.map((a) => (a.id === id ? { id, value } : a))
      } else {
        return [...attributes, { id, value }]
      }
    })
  }

  const nextSection = () => {
    if (section === 'location') {
      setSection('attributes')
    } else if (section === 'attributes') {
      setSection('photos')
    }
  }

  const prevSection = () => {
    if (section === 'photos') {
      setSection('attributes')
    } else if (section === 'attributes') {
      setSection('location')
    }
  }

  const handleFileUpload = async () => {
    const file = fileInput.current.files[0]
    if (file) {
      const uri = await justUploadPhoto({ file })
      if (uri) {
        setPhotos([...photos, uri])
      }
    }
  }

  const handleSubmit = async () => {
    const filterdAttributes = attributes.filter((a) =>
      project.attributes.some((att) => att.id === a.id)
    )
    const point = await createPoint({ lng, lat, zoom, attributes: filterdAttributes, photos })
    if (point) {
      routeTo(`/projects/${project.id}/points/${point.id}`, true)
    }
  }

  const handleClear = () => setAttributes([])

  const onClose = () => goBack(`/projects/${project.id}`)

  return (
    <BasicDialog
      full={isNarrow}
      large={!isNarrow}
      flexGrowContent={isNarrow}
      title="Ny Punkt"
      onClose={onClose}
      primaryActionText={section === 'photos' ? 'Spara' : 'Nästa'}
      onPrimaryActionClick={section === 'photos' ? handleSubmit : nextSection}
      primaryActionLoading={isCreatingPoint}
      action1Text={section === 'location' ? 'Avbryt' : 'Föregående'}
      onAction1Click={section === 'location' ? onClose : prevSection}
      secondaryActionText={section === 'attributes' ? 'Rensa' : undefined}
      onSecondaryActionClick={section === 'attributes' ? handleClear : undefined}
    >
      {section === 'location' && (
        <SelectLocation
          lat={lat}
          lng={lng}
          zoom={zoom}
          onLatChange={setLat}
          onLngChange={setLng}
          onZoomChange={setZoom}
          infoText="Kikarsiktet i kartans mitt visar var punktens position kommer placeras. Klicka på ikonen till höger för gps positionering så syns blå gps punkt. Panorera och zooma i ortofoto tills du är nöjd med punktens position. Tryck på Nästa."
        />
      )}
      {section === 'attributes' && (
        <Block>
          {project.attributes.map(({ id, name, type }) => {
            const attribute = attributes.find((a) => a.id === id)
            const value = attribute ? attribute.value : ''
            return (
              <Fragment>
                {type === 'text' && (
                  <TextArea
                    autoHeight
                    maxRows={4}
                    width="100%"
                    label={name}
                    value={value}
                    onInput={(value) => setAttribute(id, value)}
                  />
                )}
                {type === 'number' && (
                  <TextField
                    type="number"
                    width="100%"
                    label={name}
                    value={value}
                    onInput={(value) => setAttribute(id, value)}
                  />
                )}
                {type === 'boolean' && (
                  <Checkbox
                    label={name}
                    checked={value === 'TRUE'}
                    onChange={(checked) => setAttribute(id, checked ? 'TRUE' : 'FALSE')}
                  />
                )}
                {type === 'date' && (
                  <TextField
                    type="date"
                    width="100%"
                    label={name}
                    value={value}
                    onInput={(value) => setAttribute(id, value)}
                  />
                )}
                <SpacerVertical />
              </Fragment>
            )
          })}
        </Block>
      )}
      {section === 'photos' && (
        <Block>
          <BoxContainer
            renderBoxes={(width, margin) => {
              return photos.map((uri) => <PhotoBox uri={uri} width={width} margin={margin} />)
            }}
          />
          <SpacerVertical />
          <input
            style={{ display: 'none' }}
            ref={fileInput}
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
          />
          <Button
            outlined
            icon={AddIcon}
            onClick={() => fileInput.current.click()}
            loading={isUploadingPhoto}
          >
            Ladda upp foto
          </Button>
        </Block>
      )}
    </BasicDialog>
  )
}

export default connect('project, isCreatingPoint, isUploadingPhoto, isNarrow')(NewPointWizard)
