import { h, Fragment } from 'preact'
import dateFormat from 'dateformat'
import { useRef } from 'preact/hooks'
import { Block, InlineBlock, Row } from 'jsxstyle/preact'
import { connect } from 'unistore/preact'

import {
  AddAPhotoIcon,
  Button,
  DividerHorizontal,
  DividerVertical,
  SpacerHorizontal,
  SpacerVertical
} from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'
import { useMedia } from '@sodra/use-media'
import PhotosPreview from './PhotosPreview'

const PointPreviewOnDesktop = ({ point, project, isUploadingPhoto, onFileUpload }) => {
  const supportsHover = useMedia(['(hover: hover)'], [true], false)
  const fileInput = useRef()

  const handleFileUpload = async () => {
    const file = fileInput.current.files[0]
    if (file) {
      onFileUpload(file)
    }
  }

  return (
    <Block
      position="absolute"
      width="400px"
      left={point ? 0 : '-450px'}
      bottom="0"
      top="60px"
      background="var(--surface)"
      color="var(--on-surface)"
      overflow="auto"
      boxShadow="0 1px 3px var(--box-shadow-color)"
      transition="left .18s cubic-bezier(0, 0, .2, 1)"
      willChange="left"
    >
      {point && (
        <Fragment>
          <PhotosPreview
            point={point}
            projectId={project.id}
            onClickUpload={() => fileInput.current.click()}
          />
          <Block padding="10px 15px">
            <Block margin="5px 0">
              <InlineBlock>Skapad:&nbsp;</InlineBlock>
              <InlineBlock fontWeight="bold">
                {dateFormat(point.created, 'yyyy-mm-dd HH:MM')}
              </InlineBlock>
              <SpacerVertical tiny />
            </Block>
            <Block margin="5px 0">
              <InlineBlock>Skapad av:&nbsp;</InlineBlock>
              <InlineBlock fontWeight="bold">{point.user.name}</InlineBlock>
              <SpacerVertical tiny />
            </Block>
            {project.attributes.map(({ id, name, type, numDecimals }) => {
              const attribute = point.attributes.find((attribute) => attribute.id === id)
              const value = attribute ? attribute.value : ''
              let formattedValue
              if (type === 'number') {
                formattedValue = value.trim() === '' ? '' : parseFloat(value).toFixed(numDecimals)
              } else if (type === 'boolean') {
                formattedValue = value === 'TRUE' ? 'Ja' : 'Nej'
              } else {
                formattedValue = value
              }
              return (
                <Block margin="5px 0">
                  <InlineBlock>{name}:&nbsp;</InlineBlock>
                  <InlineBlock fontWeight="bold">{formattedValue}</InlineBlock>
                  <SpacerVertical tiny />
                </Block>
              )
            })}
          </Block>
          <Row alignItems="center" padding="0 15px 15px">
            <Button
              contained
              small={supportsHover}
              onClick={() => routeTo(`/projects/${project.id}/points/${point.id}`)}
            >
              Öppna
            </Button>
            <SpacerHorizontal tiny />
            <Button
              outlined
              small={supportsHover}
              onClick={() => routeTo(`/projects/${project.id}/points/${point.id}/edit-location`)}
            >
              Flytta
            </Button>
            <SpacerHorizontal tiny />
            <Button
              outlined
              small={supportsHover}
              icon={AddAPhotoIcon}
              onClick={() => fileInput.current.click()}
              loading={isUploadingPhoto}
            >
              Lägg till foto
            </Button>
          </Row>
          <input
            style={{ display: 'none' }}
            ref={fileInput}
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
          />
        </Fragment>
      )}
    </Block>
  )
}

export default connect('project, isUploadingPhoto')(PointPreviewOnDesktop)
