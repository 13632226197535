const listeners = {}

export const on = (eventName, callback) => {
  if (listeners[eventName]) {
    listeners[eventName].push(callback)
  } else {
    listeners[eventName] = [callback]
  }
  return () => off(eventName, callback)
}

export const off = (eventName, callback) => {
  if (listeners[eventName]) {
    listeners[eventName] = listeners[eventName].filter((cb) => cb !== callback)
  }
}

export const fire = (eventName, payload) => {
  if (listeners[eventName]) {
    for (let callback of listeners[eventName]) {
      callback(payload)
    }
  }
}
