import { h, render } from 'preact'
import { Provider } from 'unistore/preact'
import '@sodra/bongo-ui/default.css'

import { store } from './store'
import App from './App'
import './index.css'

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('body')
)

/*
window.addEventListener('load', () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('./service-worker.js')
  }
})
*/
