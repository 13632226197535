import { h } from 'preact'
import { Block, Row } from 'jsxstyle/preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import ResizeObserver from 'resize-observer-polyfill'

const BoxContainer = ({ prefWidth = 220, margin = 10, renderBoxes }) => {
  const container = useRef()
  const [boxWidth, setBoxWidth] = useState(prefWidth)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const containerWidth = entry.contentRect.width
        const numBoxes = Math.round(containerWidth / prefWidth)
        const boxWidth = Math.floor(containerWidth / numBoxes)
        setBoxWidth(boxWidth)
      }
    })
    resizeObserver.observe(container.current)
    return () => resizeObserver.unobserve(container.current)
  }, [prefWidth])

  return (
    <Block>
      <Row props={{ ref: container }} flexWrap="wrap" margin={`-${margin}px`}>
        {renderBoxes(boxWidth, margin)}
      </Row>
    </Block>
  )
}

export default BoxContainer
