import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import { connect } from 'unistore/preact'

import { useMap } from './use-map'

const ProjectMap = ({ project, onClick, onLocationChange, refreshVersion = 0, ...params }) => {
  const geoJsonUrl = `${process.env.API_URL}/v1/projects/${project.id}/${project.name.replace(
    /\s+/g,
    '_'
  )}.geojson`

  const [mapRef, map] = useMap(project, {
    geoLocation: true,
    setUserLocation: true,
    geoJsonUrl,
    layers: project.layers
  })
  const [bounds, setBounds] = useState()

  useEffect(() => {
    fetch(geoJsonUrl)
      .then((res) => res.json())
      .then((json) => {
        if (json.features.length > 0) {
          const bounds = new mapboxgl.LngLatBounds()
          for (let feature of json.features) {
            bounds.extend(feature.geometry.coordinates)
          }
          setBounds(bounds)
        }
      })
  }, [geoJsonUrl])

  useEffect(() => {
    if (map) {
      const source = map.getSource('points')
      if (source) {
        console.log('Update src...')
        source.setData(geoJsonUrl)
      }
    }
  }, [map, refreshVersion, geoJsonUrl])

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds, { padding: 100, animate: false, maxZoom: 15 })
    }
  }, [map, bounds])

  useEffect(() => {
    if (map) {
      map.on('idle', () => {
        const center = map.getCenter()
        const zoom = map.getZoom()
        onLocationChange({
          lat: center.lat,
          lng: center.lng,
          zoom
        })
      })
      map.on('mouseenter', 'points', () => {
        map.getCanvas().style.cursor = 'pointer'
      })
      map.on('mouseleave', 'points', () => {
        map.getCanvas().style.cursor = ''
      })
      map.on('click', function (e) {
        const bbox = [
          [e.point.x - 5, e.point.y - 5],
          [e.point.x + 5, e.point.y + 5]
        ]
        const features = map.queryRenderedFeatures(bbox, {
          layers: ['points']
        })
        map.removeFeatureState({
          source: 'points'
        })
        if (features.length > 0) {
          map.setFeatureState(
            {
              source: 'points',
              id: features[0].id
            },
            { selected: true }
          )
          onClick(features[0].properties.id)
        } else {
          onClick()
        }
      })
    }
  }, [map])

  return <Block props={{ ref: mapRef }} background="var(--surface-alternative)" {...params} />
}

export default connect('project, refreshVersion')(ProjectMap)
