import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { BasicDialog, Button, Form, Link, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { Col, Row } from 'jsxstyle/preact'

import { createUser } from '../actions'

import logo from 'url:../logo.svg'
import { routeTo } from '@sodra/prutt'

const Register = ({ isCreatingUser }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isCreated, setIsCreated] = useState(false)

  const handleSubmit = async () => {
    if (await createUser({ name, email, password })) {
      setIsCreated(true)
    }
  }

  return (
    <Row
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      alignItems="center"
      justifyContent="center"
      background="var(--surface)"
    >
      <Col alignItems="center">
        <img src={logo} width="100" />
        <SpacerVertical large />
        <Form width="240px" onSubmit={handleSubmit}>
          <TextField width="100%" label="Namn" value={name} onInput={setName} />
          <SpacerVertical small />
          <TextField width="100%" type="email" label="Epost" value={email} onInput={setEmail} />
          <SpacerVertical small />
          <TextField
            width="100%"
            type="password"
            label="Lösenord"
            value={password}
            onInput={setPassword}
          />
          <SpacerVertical small />
          <Button contained type="submit" width="100%" loading={isCreatingUser}>
            Skapa konto
          </Button>
        </Form>
        <SpacerVertical large />
        Har du redan ett konto? <Link to="/">Logga in</Link>
      </Col>
      {isCreated && (
        <BasicDialog
          disableAutofocus
          title="Konto skapat"
          primaryActionText="Stäng"
          onPrimaryActionClick={() => routeTo('/', true)}
        >
          Vi har skickat ett mail till den epost-adress du angivit. Följ instruktionerna i mailet
          för att verifiera kontot.
        </BasicDialog>
      )}
    </Row>
  )
}

export default connect('isCreatingUser, error')(Register)
