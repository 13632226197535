import { h } from 'preact'
import { connect } from 'unistore/preact'
import { Block, Col, Row } from 'jsxstyle/preact'
import {
  BackArrowIcon,
  Checkbox,
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  DownloadIcon,
  IconButton,
  OpenInNewIcon,
  SpacerHorizontal,
  SpacerVertical
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'
import DeletePhoto from './DeletePhoto'
import { selectPhoto } from '../../../actions'

export const downloadPhoto = async (url, filename) => {
  const blob = await fetch(url).then((res) => res.blob())
  const a = document.createElement('a')
  a.download = filename
  a.href = window.URL.createObjectURL(blob)
  document.body.appendChild(a)
  a.click()
  a.remove()
}

const Photo = ({ project, point, photoId }) => {
  const photo = point.photos.find((photo) => photo.id === photoId)

  if (!photo) {
    return (
      <Col
        zIndex="2"
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        background="black"
      />
    )
  }

  const index = point.photos.findIndex((photo) => photo.id === photoId)
  const nextPhotoId =
    point.photos.length === 1
      ? undefined
      : index < point.photos.length - 1
      ? point.photos[index + 1].id
      : point.photos[0].id
  const prevPhotoId =
    point.photos.length === 1
      ? undefined
      : index > 0
      ? point.photos[index - 1].id
      : point.photos[point.photos.length - 1].id

  const onClose = () => goBack(`/projects/${project.id}/points/${point.id}`)

  const onSelect = (selected) => selectPhoto(point.id, photo.id, selected)

  const ext = photo.uri.slice(photo.uri.lastIndexOf('.') + 1)
  const filename = `Foto_${index + 1}.${ext}`

  return (
    <Col zIndex="2" position="absolute" top="0" left="0" bottom="0" right="0" background="black">
      <Row alignItems="center" padding="10px 20px">
        <IconButton icon={BackArrowIcon} onClick={onClose} />
        <Block flex="1" />
        <IconButton icon={OpenInNewIcon} onClick={() => window.open(photo.uri)} />
        <SpacerHorizontal tiny />
        <IconButton icon={DownloadIcon} onClick={() => downloadPhoto(photo.uri, filename)} />
        <SpacerHorizontal tiny />
        <IconButton
          icon={DeleteIcon}
          onClick={() =>
            routeTo(`/projects/${project.id}/points/${point.id}/photos/${photo.id}/delete-photo`)
          }
        />
      </Row>
      <Block
        component="img"
        margin="10px"
        flex="1"
        width="calc(100% - 20px)"
        minHeight="100px" // Neede to make flex work
        props={{
          src: `${process.env.CROPPER_URL}?width=800&height=800&fit=inside&url=${photo.uri}`,
          alt: ''
        }}
        objectFit="contain"
        objectPosition="center center"
      />
      <Row justifyContent="space-around">
        <Checkbox
          label="Vald för export"
          checked={photo.selected}
          onChange={(checked) => {
            onSelect(checked)
          }}
          color="var(--on-inverted-surface)"
        />
      </Row>
      {nextPhotoId && (
        <Row alignItems="center" justifyContent="center">
          <IconButton
            icon={ChevronLeftIcon}
            onClick={() =>
              routeTo(`/projects/${project.id}/points/${point.id}/photos/${prevPhotoId}`, true)
            }
          />
          <SpacerHorizontal />
          <Block color="var(--accent)">
            {index + 1} / {point.photos.length}
          </Block>
          <SpacerHorizontal />
          <IconButton
            icon={ChevronRightIcon}
            onClick={() =>
              routeTo(`/projects/${project.id}/points/${point.id}/photos/${nextPhotoId}`, true)
            }
          />
        </Row>
      )}
      <SpacerVertical />
      <Route
        path="/projects/:projectId/points/:pointId/photos/:photoId/delete-photo"
        render={(params) => {
          return (
            <DeletePhoto
              {...params}
              onClose={(deleted) => {
                goBack(`/projects/${project.id}/points/${point.id}/photos/${photoId}`)
                if (deleted) {
                  setTimeout(() => {
                    if (nextPhotoId) {
                      routeTo(
                        `/projects/${project.id}/points/${point.id}/photos/${nextPhotoId}`,
                        true
                      )
                    } else {
                      goBack(`/projects/${project.id}/points/${point.id}`)
                    }
                  }, 300)
                }
              }}
            />
          )
        }}
      />
    </Col>
  )
}

export default connect('project, point')(Photo)
