import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { BasicDialog } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { updatePoint } from '../../../actions'
import SelectLocation from '../SelectLocation'

const EditLocation = ({ project, point, isUpdatingPoint, isNarrow }) => {
  const [lat, setLat] = useState(point.lat)
  const [lng, setLng] = useState(point.lng)
  const [zoom, setZoom] = useState(point.zoom)

  const handleSubmit = async () => {
    if (await updatePoint({ lat, lng, zoom })) {
      onClose()
    }
  }

  const onClose = () => goBack(`/projects/${project.id}/points/${point.id}`)

  return (
    <BasicDialog
      large={!isNarrow}
      full={isNarrow}
      flexGrowContent={isNarrow}
      disableAutofocus
      title="Plats"
      primaryActionText="Spara"
      primaryActionLoading={isUpdatingPoint}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <SelectLocation
        showPoints={true}
        currentPointId={point.id}
        lat={lat}
        lng={lng}
        zoom={zoom}
        onLatChange={setLat}
        onLngChange={setLng}
        onZoomChange={setZoom}
      />
    </BasicDialog>
  )
}

export default connect('project, point, isUpdatingPoint, isNarrow')(EditLocation)
