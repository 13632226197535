import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Button, Form, Link, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { Col, Row } from 'jsxstyle/preact'

import { forgotPassword } from '../actions'

import logo from 'url:../logo.svg'
import { routeTo } from '@sodra/prutt'

const ForgotPassword = ({ isForgettingPassword }) => {
  const [email, setEmail] = useState('')

  const handleSubmit = async () => {
    if (await forgotPassword({ email })) {
      routeTo('/', true)
    }
  }

  return (
    <Row
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      alignItems="center"
      justifyContent="center"
      background="var(--surface)"
    >
      <Col alignItems="center">
        <img src={logo} width="100" />
        <SpacerVertical large />
        <p>Fyll i din epost så skickar vi en länk där du kan välja ett nytt lösenord.</p>
        <SpacerVertical />
        <Form width="240px" onSubmit={handleSubmit}>
          <TextField width="100%" type="email" label="Epost" value={email} onInput={setEmail} />
          <SpacerVertical small />
          <Button contained type="submit" width="100%" loading={isForgettingPassword}>
            Skicka
          </Button>
        </Form>
        <SpacerVertical large />
        <Link to="/">Logga in</Link>
      </Col>
    </Row>
  )
}

export default connect('isForgettingPassword')(ForgotPassword)
