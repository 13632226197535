import { h, Fragment } from 'preact'
import { Block, Col, InlineBlock, Row } from 'jsxstyle/preact'

import { AddAPhotoIcon, Button } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

const photoUrl = (photo, projectId, pointId) =>
  `/projects/${projectId}/points/${pointId}/photos/${photo.id}`

const Photo = ({ photo, url, width = '100%', height = '100%' }) => {
  return (
    <Block
      flexShrink="0"
      component="img"
      width={width}
      height={height}
      objectFit="cover"
      objectPosition="center center"
      cursor="pointer"
      props={{
        src: `${process.env.CROPPER_URL}?width=800&height=800&fit=inside&url=${photo.uri}`,
        alt: '',
        onClick: () => routeTo(url)
      }}
    />
  )
}

const MorePhoto = ({ numMore, photo, url, width = '100%', height = '100%' }) => {
  return (
    <Block
      position="relative"
      flexShrink="0"
      width={width}
      height={height}
      cursor="pointer"
      props={{
        onClick: () => routeTo(url)
      }}
    >
      <Block
        component="img"
        width="100%"
        height="100%"
        objectFit="cover"
        objectPosition="center center"
        props={{
          src: photo.uri,
          alt: ''
        }}
      />
      <Block
        position="absolute"
        top="0"
        right="0"
        left="0"
        bottom="0"
        background="rgba(0, 0, 0, 0.6)"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="white"
      >
        <InlineBlock>+{numMore}</InlineBlock>
      </Block>
    </Block>
  )
}

const PhotosPreview = ({ point, projectId, height = '200px', isUploadingPhoto, onClickUpload }) => {
  const photos = point.photos
  return (
    <Row height={height} background="var(--container-background)">
      {photos.length === 0 && (
        <Block width="100%" display="flex" justifyContent="center" alignItems="center">
          <Button contained icon={AddAPhotoIcon} onClick={onClickUpload} loading={isUploadingPhoto}>
            Lägg till foto
          </Button>
        </Block>
      )}
      {photos.length === 1 && (
        <Photo photo={photos[0]} url={photoUrl(photos[0], projectId, point.id)} />
      )}
      {photos.length === 2 && (
        <Fragment>
          <Photo width="50%" photo={photos[0]} url={photoUrl(photos[0], projectId, point.id)} />
          <Photo width="50%" photo={photos[1]} url={photoUrl(photos[1], projectId, point.id)} />
        </Fragment>
      )}
      {photos.length === 3 && (
        <Fragment>
          <Photo width="50%" photo={photos[0]} url={photoUrl(photos[0], projectId, point.id)} />
          <Col width="50%">
            <Photo height="50%" photo={photos[1]} url={photoUrl(photos[1], projectId, point.id)} />
            <Photo height="50%" photo={photos[2]} url={photoUrl(photos[2], projectId, point.id)} />
          </Col>
        </Fragment>
      )}
      {photos.length >= 4 && (
        <Fragment>
          <Photo width="50%" photo={photos[0]} url={photoUrl(photos[0], projectId, point.id)} />
          <Col width="50%">
            <Photo height="50%" photo={photos[1]} url={photoUrl(photos[1], projectId, point.id)} />
            <Row height="50%">
              <Photo width="50%" photo={photos[2]} url={photoUrl(photos[2], projectId, point.id)} />
              {photos.length === 4 ? (
                <Photo
                  width="50%"
                  photo={photos[3]}
                  url={photoUrl(photos[3], projectId, point.id)}
                />
              ) : (
                <MorePhoto
                  numMore={photos.length - 4}
                  width="50%"
                  photo={photos[3]}
                  url={`/projects/${projectId}/points/${point.id}/photos/`}
                />
              )}
            </Row>
          </Col>
        </Fragment>
      )}
    </Row>
  )
}

export default PhotosPreview
