import { h } from 'preact'
import { connect } from 'unistore/preact'
import { BasicDialog, Form, TextField } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { createInvitation } from '../../../actions'
import { useState } from 'preact/hooks'

const AddMember = ({ project, isCreatingInvitation }) => {
  const [email, setEmail] = useState('')

  const onClose = () => goBack(`/projects/${project.id}/members`)

  const handleSubmit = async () => {
    if (await createInvitation(email)) {
      onClose()
    }
  }

  return (
    <BasicDialog
      title="Ny medlem"
      primaryActionText="Bjud in"
      primaryActionLoading={isCreatingInvitation}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <TextField width="100%" label="Epost" value={email} onInput={setEmail} />
      </Form>
    </BasicDialog>
  )
}

export default connect('project, isCreatingInvitation')(AddMember)
