import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'
import { connect } from 'unistore/preact'
import { SearchIcon, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { get } from '../../api'

import { useMap } from './use-map'

const SelectLocation = ({
  project,
  lat: startLat = 0,
  lng: startLng = 0,
  zoom: startZoom = 0,
  onLatChange,
  onLngChange,
  onZoomChange,
  showPoints = true,
  infoText,
  currentPointId
}) => {
  const [query, setQuery] = useState('')
  const [lat, setLat] = useState(startLat)
  const [lng, setLng] = useState(startLng)
  const [zoom, setZoom] = useState(startZoom)
  const [suggest, setSuggest] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [isSatellite, setIsSatellite] = useState()

  const geoJsonUrl = `${process.env.API_URL}/v1/projects/${project.id}/${project.name.replace(
    /\s+/g,
    '_'
  )}.geojson`

  const [mapRef, map] = useMap(project, {
    center: [lng, lat],
    zoom,
    geoLocation: true,
    geoJsonUrl: showPoints ? geoJsonUrl : undefined,
    layers: project.layers,
    currentPointId
  })

  useEffect(() => {
    if (map) {
      map.panTo([lng, lat])
      const handleIdle = () => {
        const { lng, lat } = map.getCenter()
        setLat(lat)
        setLng(lng)
        onLatChange(lat)
        setZoom(map.getZoom())
        onZoomChange(map.getZoom())
        onLngChange(lng)
      }
      const handleStyleLoad = () => {
        setIsSatellite(map.getStyle().name === 'Mapbox Satellite')
      }
      map.on('idle', handleIdle)
      map.on('style.load', handleStyleLoad)
      return () => {
        map.off('idle', handleIdle)
        map.off('style.load', handleStyleLoad)
      }
    }
  }, [map])

  useEffect(() => {
    if (query.trim() === '') {
      setSuggest([])
      return
    }
    let cancelled = false
    const timeout = setTimeout(() => {
      setIsSearching(true)
      get(`/geocode`, { query })
        .then(({ data: places }) => {
          if (!cancelled) {
            setSuggest(places)
          }
        })
        .catch((err) => {
          if (!cancelled) {
            console.warn(err)
          }
        })
        .finally(() => {
          if (!cancelled) {
            setIsSearching(false)
          }
        })
    }, 500)
    return () => {
      clearTimeout(timeout)
      cancelled = true
    }
  }, [query])

  const handleSubmit = async () => {
    if (await updatePoint({ lat, lng, zoom })) {
      onClose()
    }
  }

  const onClose = () => goBack(`/projects/${project.id}/points/${point.id}`)

  const updatePosition = (str) => {
    const [lng, lat] = str.split(',').map(parseFloat)
    setLat(lat)
    setLng(lng)
  }

  const handleSuggestClose = () => {
    setSuggest([])
  }

  const handleSuggestSelect = (elem) => {
    setSuggest([])
    if (elem.bbox) {
      map.fitBounds(elem.bbox)
    } else if (elem.center) {
      map.flyTo({ center: elem.center, zoom: 15 })
    }
  }

  const crosshairColor = isSatellite ? 'white' : 'var(--accent)'

  return (
    <Col height="100%">
      <TextField
        label="Sök plats"
        icon={SearchIcon}
        clearable
        value={query}
        onInput={setQuery}
        onChange={setQuery}
        suggest={suggest}
        suggestMode="below"
        onSuggestSelect={handleSuggestSelect}
        onSuggestClose={handleSuggestClose}
      />
      <SpacerVertical small />
      <Block>
        {infoText ||
          'Kikarsiktet i kartans mitt visar punktens position. Panorera och zooma i kartan tills du är nöjd.'}
      </Block>
      <SpacerVertical small />
      <Block
        position="relative"
        flex="1"
        minHeight="250px"
        props={{ ref: mapRef }}
        background="var(--surface-alternative)"
      >
        <Row
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top="50%"
          left="50%"
          width="120px"
          height="120px"
          marginLeft="-60px"
          marginTop="-60px"
          zIndex="99"
          background={isSatellite ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)'}
          borderRadius="50%"
          pointerEvents="none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            id="root"
            version="1.1"
            viewBox="0 0 16 16"
            stroke-width="0.75"
          >
            <circle fill="none" stroke={crosshairColor} cx="8" cy="8" r="6" />
            <path fill="none" stroke={crosshairColor} d="M 8 0 L 8 6.5" />
            <path fill="none" stroke={crosshairColor} d="M 0 8 L 6.5 8" />
            <path fill="none" stroke={crosshairColor} d="M 8 9.5 L 8 16" />
            <path fill="none" stroke={crosshairColor} d="M 9.5 8 L 16 8" />
          </svg>
        </Row>
      </Block>
      <SpacerVertical small />
      <TextField
        label="Position (lng, lat)"
        value={`${lng.toFixed(5)}, ${lat.toFixed(5)}`}
        onChange={updatePosition}
      />
    </Col>
  )
}

export default connect('project')(SelectLocation)
