import { Fragment, h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'
import { connect } from 'unistore/preact'
import {
  AddIcon,
  BackArrowIcon,
  Button,
  DeleteIcon,
  Divider,
  DropdownMenu,
  EditIcon,
  IconButton,
  PlaceIcon,
  List,
  ListIcon,
  ListItem,
  MoreIcon,
  ProgressCircular,
  SpacerHorizontal,
  SpacerVertical,
  TextArea,
  TextField,
  ViewListIcon,
  ViewModuleIcon,
  CheckmarkIcon,
  Checkbox
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'
import dateFormat from 'dateformat'

import { fetchPoint, selectPhoto, uploadPhoto } from '../../../actions'
import { useLocalStorageState } from '../../../use-local-storage-state'

import BoxContainer from '../BoxContainer'
import DeletePoint from '../DeletePoint'

import Photo from './Photo'
import DeletePhoto from './DeletePhoto'
import EditAttributes from './EditAttributes'
import EditSettings from './EditSettings'
import EditLocation from './EditLocation'
import PointMap from './PointMap'

const PhotoBox = ({ photo, width, margin, onClick, onSelect }) => {
  const { uri } = photo
  const src = `${process.env.CROPPER_URL}?url=${uri}&width=200&height=200&fit=cover`
  return (
    <Block margin={`${margin}px`}>
      <Block
        component="img"
        width={`${width - 2 * margin}px`}
        height={`${width - 2 * margin}px`}
        objectFit="cover"
        objectPosition="center bottom"
        cursor="pointer"
        props={{
          onClick,
          alt: '',
          src
        }}
      />
      <Checkbox
        label="Vald för export"
        checked={photo.selected}
        onChange={(checked) => {
          onSelect(checked)
        }}
      />
    </Block>
  )
}

const Point = ({ pointId, point, project, isUploadingPhoto }) => {
  const fileInput = useRef()
  const [menu, setMenu] = useState()
  const [photoMode, setPhotoMode] = useLocalStorageState('point:photoMode', 'grid')

  useEffect(() => {
    fetchPoint(pointId)
  }, [pointId])

  const closeMenu = () => setMenu()

  const togglePhotoMode = () => {
    setPhotoMode((photoMode) => (photoMode === 'list' ? 'grid' : 'list'))
  }

  const handleFileUpload = async () => {
    const file = fileInput.current.files[0]
    if (file && (await uploadPhoto({ file }))) {
      console.log('Uploaded!')
    }
  }

  if (!point || point.id !== pointId) {
    return (
      <Col
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        overflow="hidden"
        background="var(--surface)"
        zIndex="2"
        justifyContent="center"
        alignItems="center"
      >
        <ProgressCircular />
      </Col>
    )
  }

  return (
    <Col
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      overflow="hidden"
      background="var(--surface)"
      zIndex="2"
    >
      <Row
        alignItems="center"
        padding="10px 20px"
        borderBottom="solid 1px var(--on-surface-lighter)"
      >
        <IconButton icon={BackArrowIcon} onClick={() => goBack(`/projects/${project.id}`)} />
        <Block flex="1" />
        <IconButton
          icon={MoreIcon}
          onClick={({ type, target, x, y }) => {
            if (type === 'keyboard') {
              setMenu({ positionElement: target })
            } else {
              setMenu({ position: { x, y } })
            }
          }}
        />
      </Row>
      <Block flex="1" overflow="auto">
        <PointMap point={point} width="100%" height="240px" />
        <Block width="900px" maxWidth="calc(100% - 40px)" margin="auto">
          <SpacerVertical />
          <Button
            outlined
            onClick={() => routeTo(`/projects/${project.id}/points/${point.id}/edit-location`)}
          >
            Flytta
          </Button>
          <SpacerVertical />
          <TextField
            width="100%"
            readonly
            label="Skapad"
            value={dateFormat(point.created, 'yyyy-mm-dd HH:MM')}
          />
          <TextField width="100%" readonly label="Skapad av" value={point.user.name} />
          {project.attributes.length > 0 && (
            <Fragment>
              <SpacerVertical large />
              <Row alignItems="center">
                <Block>Attribut</Block>
                <Block flex="1" />
                <Button
                  small
                  icon={EditIcon}
                  onClick={() =>
                    routeTo(`/projects/${project.id}/points/${point.id}/edit-attributes`)
                  }
                >
                  Ändra
                </Button>
              </Row>
              <SpacerVertical small />
              <Divider />
              <SpacerVertical />
              {project.attributes.map(({ id, name, type, numDecimals }) => {
                const attribute = point.attributes.find((a) => a.id === id)
                const value = attribute ? attribute.value : ''
                return (
                  <Fragment>
                    {type === 'text' && (
                      <TextArea
                        autoHeight
                        maxRows={4}
                        width="100%"
                        readonly
                        label={name}
                        value={value.trim() === '' ? '–' : value}
                      />
                    )}
                    {type === 'number' && (
                      <TextField
                        width="100%"
                        readonly
                        label={name}
                        value={value.trim() === '' ? '–' : parseFloat(value).toFixed(numDecimals)}
                      />
                    )}
                    {type === 'boolean' && (
                      <TextField
                        width="100%"
                        readonly
                        label={name}
                        value={value === 'TRUE' ? 'Ja' : 'Nej'}
                      />
                    )}
                    {type === 'date' && value && (
                      <TextField type="date" width="100%" readonly label={name} value={value} />
                    )}
                    {type === 'date' && !value && (
                      <TextField width="100%" readonly label={name} value="–" />
                    )}
                  </Fragment>
                )
              })}
            </Fragment>
          )}
          <SpacerVertical large />
          <Row alignItems="center">
            <Block>Foton</Block>
            <Block flex="1" />
            <Button
              small
              icon={AddIcon}
              onClick={() => fileInput.current.click()}
              loading={isUploadingPhoto}
            >
              Nytt foto
            </Button>
            <SpacerHorizontal tiny />
            <IconButton
              icon={photoMode === 'list' ? ViewModuleIcon : ViewListIcon}
              onClick={togglePhotoMode}
            />
          </Row>
          <input
            style={{ display: 'none' }}
            ref={fileInput}
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
          />
          <SpacerVertical small />
          <Divider />
          <SpacerVertical />
          {photoMode === 'list' && (
            <List>
              {point.photos.map((photo) => {
                const src = `${process.env.CROPPER_URL}?url=${photo.uri}&width=200&height=200&fit=cover`
                return (
                  <ListItem
                    visual={
                      <Block
                        position="relative"
                        backgroundImage={`url(${src})`}
                        backgroundSize="cover"
                        width="40px"
                        height="40px"
                        props={{
                          onClick: (e) => {
                            e.stopPropagation()
                            selectPhoto(point.id, photo.id, !photo.selected)
                          }
                        }}
                      >
                        <CheckmarkIcon
                          position="absolute"
                          bottom="0"
                          right="0"
                          size="12"
                          background={photo.selected ? 'var(--accent)' : 'var(--surface)'}
                          fill={photo.selected ? 'var(--on-accent)' : 'var(--on-surface-lighter)'}
                        />
                      </Block>
                    }
                    secondaryText={photo.user.name}
                    text={dateFormat(photo.created, 'yyyy-mm-dd HH:MM')}
                    actionIcon={DeleteIcon}
                    onActionClick={() =>
                      routeTo(`/projects/${project.id}/points/${point.id}/delete-photo/${photo.id}`)
                    }
                    onClick={() =>
                      routeTo(`/projects/${project.id}/points/${point.id}/photos/${photo.id}`)
                    }
                  />
                )
              })}
            </List>
          )}
          {photoMode === 'grid' && (
            <BoxContainer
              renderBoxes={(width, margin) => {
                return point.photos.map((photo) => (
                  <PhotoBox
                    photo={photo}
                    width={width}
                    margin={margin}
                    onSelect={(select) => selectPhoto(point.id, photo.id, select)}
                    onClick={() =>
                      routeTo(`/projects/${project.id}/points/${point.id}/photos/${photo.id}`)
                    }
                  />
                ))
              }}
            />
          )}
        </Block>
      </Block>
      {menu && (
        <DropdownMenu
          onClose={closeMenu}
          position={menu.position}
          positionElement={menu.positionElement}
        >
          <SpacerVertical tiny />
          <List autofocus delayClick>
            <ListItem
              icon={PlaceIcon}
              text="Plats"
              onClick={() => routeTo(`/projects/${project.id}/points/${point.id}/edit-location`)}
            />
            <ListItem
              icon={ListIcon}
              text="Attribut"
              onClick={() => routeTo(`/projects/${project.id}/points/${point.id}/edit-attributes`)}
            />
            <Divider />
            <ListItem
              icon={DeleteIcon}
              text="Radera punkt"
              onClick={() => routeTo(`/projects/${project.id}/points/${point.id}/delete`)}
            />
          </List>
          <SpacerVertical tiny />
        </DropdownMenu>
      )}
      <SpacerVertical />
      <Route path="/projects/:projectId/points/:pointId/settings" component={EditSettings} />
      <Route path="/projects/:projectId/points/:pointId/edit-location" component={EditLocation} />
      <Route
        path="/projects/:projectId/points/:pointId/edit-attributes"
        component={EditAttributes}
      />
      <Route path="/projects/:projectId/points/:pointId/photos/:photoId" component={Photo} />
      <Route
        path="/projects/:projectId/points/:pointId/delete"
        render={(props) => (
          <DeletePoint {...props} onDelete={() => routeTo(`/projects/${project.id}`, true)} />
        )}
      />
      <Route
        path="/projects/:projectId/points/:pointId/delete-photo/:photoId"
        render={(params) => {
          return (
            <DeletePhoto
              {...params}
              onClose={() => goBack(`/projects/${project.id}/points/${point.id}`)}
            />
          )
        }}
      />
    </Col>
  )
}

export default connect('project, point, isUploadingPhoto')(Point)
