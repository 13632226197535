import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { BasicDialog, Checkbox, Form, SpacerVertical, TextArea, TextField } from '@sodra/bongo-ui'
import { goBack, routeTo } from '@sodra/prutt'
import { createProject } from '../../actions'

const CreateProject = ({ isCreatingProject, isNarrow }) => {
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [description, setDescription] = useState('')
  const [isPublic, setIsPublic] = useState(true)

  const handleSubmit = async () => {
    if (name.trim() === '') {
      setNameError('Du måste ange ett namn')
      return
    }

    const project = await createProject({ name, description, private: !isPublic })
    if (project) {
      routeTo(`/projects/${project.id}`, true)
    }
  }

  const onClose = () => goBack(`/projects`)

  return (
    <BasicDialog
      full={isNarrow}
      flexGrowContent={isNarrow}
      disableAutofocus
      title="Nytt projekt"
      primaryActionText="Spara"
      primaryActionLoading={isCreatingProject}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <TextField width="100%" label="Namn" value={name} onInput={setName} errorText={nameError} />
        <SpacerVertical />
        <TextArea width="100%" label="Beskrivning" value={description} onInput={setDescription} />
        <SpacerVertical />
        <Checkbox label="Öppet för alla användare" checked={isPublic} onChange={setIsPublic} />
      </Form>
    </BasicDialog>
  )
}

export default connect('isCreatingProject, isNarrow')(CreateProject)
