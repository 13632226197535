import { Fragment, h } from 'preact'
import { connect } from 'unistore/preact'
import { Block, Row } from 'jsxstyle/preact'
import {
  Avatar,
  BasicDialog,
  Button,
  DeleteIcon,
  List,
  ListItem,
  SpacerHorizontal,
  SpacerVertical
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { addMember } from '../../../actions'

import SettingsTabs from '../SettingsTabs'

import DeleteMember from './DeleteMember'
import DeleteInvitation from './DeleteInvitation'
import AddMember from './AddMember'
import Settings from '../Settings'

const Members = ({ project, isNarrow, isAddingMember }) => {
  const onClose = () => goBack(`/projects/${project.id}`)

  return (
    <BasicDialog
      large={!isNarrow}
      full={isNarrow}
      flexGrowContent={isNarrow}
      disableAutofocus
      title="Inställningar"
      action1Text="Stäng"
      onAction1Click={onClose}
      secondaryActionText="Ny medlem"
      secondaryActionStyle="outlined"
      onSecondaryActionClick={() => routeTo(`/projects/${project.id}/members/add-member`)}
      onClose={onClose}
    >
      <SettingsTabs active="members" />
      <SpacerVertical />
      <List>
        {project.members.map((user) => {
          return (
            <ListItem
              visual={<Avatar src={user.picture} name={user.name} />}
              text={user.name}
              secondaryText={user.email}
              actionIcon={DeleteIcon}
              onActionClick={() =>
                routeTo(`/projects/${project.id}/members/delete-member/${user.id}`)
              }
            />
          )
        })}
      </List>
      {project.invitations.length > 0 && (
        <Fragment>
          <SpacerVertical />
          <Block fontSize="18px">Inbjudna</Block>
          <SpacerVertical />
          <List>
            {project.invitations.map((inv) => {
              return (
                <ListItem
                  visual={<Avatar name={inv.email} />}
                  text={inv.email}
                  actionIcon={DeleteIcon}
                  onActionClick={() =>
                    routeTo(`/projects/${project.id}/members/delete-invitation/${inv.id}`)
                  }
                />
              )
            })}
          </List>
        </Fragment>
      )}
      {project.requests.length > 0 && (
        <Fragment>
          <SpacerVertical />
          <Block fontSize="18px">Förfrågningar</Block>
          <SpacerVertical />
          <List>
            {project.requests.map((user) => {
              return (
                <ListItem
                  visual={<Avatar src={user.picture} name={user.name} />}
                  text={
                    <Row alignItems="center">
                      <Block flex="1">
                        <Block>{user.name}</Block>
                        <SpacerVertical tiny />
                        <Block fontSize="14px" color="var(--on-surface-light)">
                          {user.email}
                        </Block>
                      </Block>
                      <Button
                        small
                        contained
                        loading={isAddingMember}
                        onClick={() => addMember(user.id)}
                      >
                        Godkänn
                      </Button>
                      <SpacerHorizontal />
                    </Row>
                  }
                  actionIcon={DeleteIcon}
                  onActionClick={() =>
                    routeTo(`/projects/${project.id}/members/delete-request/${user.id}`)
                  }
                />
              )
            })}
          </List>
        </Fragment>
      )}
      <Route path="/projects/:projectId/members/delete-member/:userId" component={DeleteMember} />
      <Route
        path="/projects/:projectId/members/delete-invitation/:invitationId"
        component={DeleteInvitation}
      />
      <Route path="/projects/:projectId/members/add-member" component={AddMember} />
    </BasicDialog>
  )
}

export default connect('project, isNarrow, isAddingMember')(Members)
