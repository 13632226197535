import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { BasicDialog, Form, SpacerVertical, TextArea, TextField } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { updatePoint } from '../../../actions'

const EditSettings = ({ project, point, isUpdatingPoint, isNarrow }) => {
  const [name, setName] = useState(point.name)
  const [nameError, setNameError] = useState('')
  const [description, setDescription] = useState(point.description)

  const handleSubmit = async () => {
    if (name.trim() === '') {
      setNameError('Du måste ange ett namn')
      return
    }
    if (await updatePoint({ name, description })) {
      onClose()
    }
  }

  const onClose = () => goBack(`/projects/${project.id}/points/${point.id}`)

  return (
    <BasicDialog
      full={isNarrow}
      flexGrowContent={isNarrow}
      disableAutofocus
      title="Inställningar"
      primaryActionText="Spara"
      primaryActionLoading={isUpdatingPoint}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <SpacerVertical small />
        <TextField width="100%" label="Namn" value={name} onInput={setName} errorText={nameError} />
        <SpacerVertical />
        <TextArea width="100%" label="Description" value={description} onInput={setDescription} />
      </Form>
      <SpacerVertical small />
    </BasicDialog>
  )
}

export default connect('project, point, isUpdatingPoint, isNarrow')(EditSettings)
