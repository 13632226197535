import { h } from 'preact'
import { connect } from 'unistore/preact'
import { Block, Row } from 'jsxstyle/preact'
import {
  Avatar,
  IconButton,
  MenuIcon,
  SearchIcon,
  SpacerHorizontal,
  TextField
} from '@sodra/bongo-ui'

const Header = ({ user, onShowMenu, isNarrow, query, setQuery }) => {
  if (isNarrow) {
    return (
      <Row
        alignItems="center"
        padding="10px 20px"
        borderBottom="solid 1px var(--on-surface-lighter)"
      >
        <IconButton flexShrink="0" icon={MenuIcon} onClick={onShowMenu} />
        <SpacerHorizontal />
        <TextField
          clearable
          flex="1"
          label="Sök användare"
          icon={SearchIcon}
          value={query}
          onInput={setQuery}
          onChange={setQuery}
        />
      </Row>
    )
  }

  return (
    <Row alignItems="center" padding="10px 20px" borderBottom="solid 1px var(--on-surface-lighter)">
      <Block flex="1">
        <IconButton icon={MenuIcon} onClick={onShowMenu} />
      </Block>
      <SpacerHorizontal />
      <Block width="900px">
        <TextField
          clearable
          width="100%"
          label="Sök användare"
          icon={SearchIcon}
          value={query}
          onInput={setQuery}
          onChange={setQuery}
        />
      </Block>
      <SpacerHorizontal />
      <Row flex="1" justifyContent="flex-end">
        <Avatar src={user.picture} name={user.name} />
      </Row>
    </Row>
  )
}

export default connect('user, isNarrow')(Header)
