import { store } from '../store'
import { del, get, patch, post } from '../api'

const incRefreshVersion = () => {
  const refreshVersion = store.getState().refreshVersion || 0
  store.setState({ refreshVersion: refreshVersion + 1 })
}

export const fetchPoints = async () => {
  try {
    store.setState({ isFetchingPoints: true })
    const projectId = store.getState().project.id
    const { data: points } = await get('/points', { projectId })
    store.setState({ points, isFetchingPoints: false })
    return points
  } catch (error) {
    store.setState({ error, isFetchingPoints: false })
  }
}

export const fetchPoint = async (pointId) => {
  try {
    store.setState({ isFetchingPoint: true })
    const { data: point } = await get(`/points/${pointId}`)
    store.setState({ point, isFetchingPoint: false })
    return point
  } catch (error) {
    store.setState({ error, isFetchingPoint: false })
  }
}

export const createPoint = async (params) => {
  try {
    store.setState({ isCreatingPoint: true })
    const projectId = store.getState().project.id
    const userId = store.getState().user.id
    const { data: point } = await post(`/points`, { projectId, userId, ...params })
    store.setState({ point, isCreatingPoint: false })
    incRefreshVersion()
    return point
  } catch (error) {
    store.setState({ error, isCreatingPoint: false })
  }
}

export const updatePoint = async (params) => {
  try {
    store.setState({ isUpdatingPoint: true })
    const pointId = store.getState().point.id
    const { data: point } = await patch(`/points/${pointId}`, params)
    store.setState({ point, isUpdatingPoint: false })
    incRefreshVersion()
    return point
  } catch (error) {
    store.setState({ error, isUpdatingPoint: false })
  }
}

export const deletePoint = async (pointId) => {
  try {
    store.setState({ isDeletingPoint: true })
    await del(`/points/${pointId}`)
    store.setState({ isDeletingPoint: false })
    incRefreshVersion()
    return true
  } catch (error) {
    store.setState({ error, isDeletingPoint: false })
  }
}
