export const formatAttribute = (type) => {
  if (type === 'text') {
    return 'Text'
  }
  if (type === 'number') {
    return 'Siffra'
  }
  if (type === 'boolean') {
    return 'Kryssruta'
  }
  if (type === 'date') {
    return 'Datum'
  }
  return type
}

export const typeOptions = [
  { value: 'text', text: formatAttribute('text') },
  { value: 'number', text: formatAttribute('number') },
  { value: 'boolean', text: formatAttribute('boolean') },
  { value: 'date', text: formatAttribute('date') }
]
