import { h } from 'preact'
import { connect } from 'unistore/preact'
import { BasicDialog } from '@sodra/bongo-ui'

import { deleteProject } from '../../actions'
import { goBack, routeTo } from '@sodra/prutt'

const DeleteProject = ({ project, isDeletingProject }) => {
  const onClose = () => {
    goBack(`/projects/${project.id}`)
  }

  const handleSubmit = async () => {
    if (await deleteProject(project.id)) {
      routeTo('/', true)
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      title="Radera projekt"
      primaryActionText="Radera"
      primaryActionLoading={isDeletingProject}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      Vill du radera projektet <strong>{project.name}</strong>?
    </BasicDialog>
  )
}

export default connect('project, isDeletingProject')(DeleteProject)
