import { h } from 'preact'
import { useState } from 'preact/hooks'
import { routeTo } from '@sodra/prutt'
import { Button, SpacerHorizontal, SpacerVertical } from '@sodra/bongo-ui'
import { Col, Row } from 'jsxstyle/preact'

import logo from 'url:../../logo.svg'
import Header from './Header'
import LeftMenu from './LeftMenu'

const Welcome = () => {
  const [menuVisible, setMenuVisible] = useState(false)

  return (
    <Col position="absolute" top="0" left="0" bottom="0" right="0" background="var(--surface)">
      <Header onShowMenu={() => setMenuVisible(true)} />
      <Row flex="1" alignItems="center" justifyContent="center">
        <Col alignItems="center" maxWidth="500px" margin="0 20px">
          <img src={logo} width="100" />
          <SpacerVertical large />
          <p>Välkommen till Callunas fotoapp!</p>
          <ul>
            <li>
              Börja med att skapa ett projekt. För foton i Callunaprojekt – döp projektet efter
              Callunas projektnummer.
            </li>
            <li>Ta foto som knyts till en punkt.</li>
            <li>
              Samla in attributdata. Du kan själv skapa en attributstruktur eller använda default.
            </li>
            <li>
              Ladda ned fotona och GIS-punkter. I GIS finns även URL till de molnlagrade bilderna.
            </li>
            <li>
              Radera projektet när användningen är klar om punkterna inte behöver sparas. Calluna
              gör rensning av de molnlagrade fotona vid behov men kontaktar den som skapat projektet
              och frågar först.
            </li>
          </ul>
          <p>Vid frågor kontakta Anna Koffman.</p>
          <SpacerVertical />
          <Row alignItems="cetner">
            <Button onClick={() => routeTo('/projects')}>Sök projekt</Button>
            <SpacerHorizontal />
            <Button
              onClick={() => {
                routeTo('/projects')
                routeTo('/projects/create-project')
              }}
            >
              Skapa nytt projekt
            </Button>
          </Row>
        </Col>
      </Row>
      {menuVisible && <LeftMenu onClose={() => setMenuVisible(false)} />}
    </Col>
  )
}

export default Welcome
