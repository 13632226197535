import dateFormat from 'dateformat'

export const formatDuration = (date) => {
  if (!date) {
    return '–'
  }
  const now = new Date()
  const then = new Date(date)
  const duration = now - then
  const seconds = Math.round(duration / 1000)
  if (seconds < 60) {
    return `just now`
  }
  const minutes = Math.round(seconds / 60)
  if (minutes < 60) {
    return `${minutes} min ago`
  }
  const hours = Math.round(minutes / 60)
  if (hours < 3) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`
  }
  return dateFormat(date, 'yyyy-mm-dd HH:MM')
}
