import { h } from 'preact'
import { connect } from 'unistore/preact'
import { BasicDialog } from '@sodra/bongo-ui'

import { deletePhoto } from '../../../actions'

const DeletePhoto = ({ photoId, isDeletingPhoto, onClose }) => {
  const handleSubmit = async () => {
    if (await deletePhoto(photoId)) {
      onClose(true)
    }
  }

  const cancel = () => onClose(false)

  return (
    <BasicDialog
      disableAutofocus
      title="Ta bort foto"
      primaryActionText="Ta bort"
      primaryActionLoading={isDeletingPhoto}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={cancel}
      onClose={cancel}
    >
      Vill du ta bort fotot?
    </BasicDialog>
  )
}

export default connect('project, point, isDeletingPhoto')(DeletePhoto)
