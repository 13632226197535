import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'
import { connect } from 'unistore/preact'
import {
  Avatar,
  BackArrowIcon,
  Button,
  Form,
  IconButton,
  SpacerHorizontal,
  SpacerVertical,
  TextField
} from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { updateUser } from '../../actions'

const Profile = ({ user, isUpdatingUser }) => {
  const [name, setName] = useState(user.name)
  const [email, setEmail] = useState(user.email)
  const [password, setPassword] = useState()

  const handleSubmit = async () => {
    updateUser({ name, email, password })
  }

  return (
    <Col
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      overflow="hidden"
      background="var(--surface)"
      zIndex="2"
    >
      <Row
        alignItems="center"
        padding="10px 20px"
        background="var(--surface)"
        zIndex="1"
        boxShadow="0 1px 3px var(--box-shadow-color)"
      >
        <IconButton icon={BackArrowIcon} onClick={() => goBack(`/projects`)} />
        <SpacerHorizontal />
        <Block
          flex="1"
          textAlign="center"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {user.name}
        </Block>
        <SpacerHorizontal />
      </Row>
      <SpacerVertical />
      <Block margin="auto" overflow="auto">
        <Row justifyContent="center">
          <Avatar huge src={user.picture} name={user.name} />
        </Row>
        <SpacerVertical large />
        <Form onSubmit={handleSubmit}>
          <TextField width="240px" label="Namn" value={name} onInput={setName} />
          <SpacerVertical />
          <TextField width="240px" label="Epost" value={email} onInput={setEmail} />
          <SpacerVertical />
          <TextField
            width="240px"
            type="password"
            label="Lösenord"
            value={password}
            onInput={setPassword}
          />
          <SpacerVertical />
          <Row alignItems="center" justifyContent="center">
            <Button type="submit" contained loading={isUpdatingUser}>
              Spara
            </Button>
            <SpacerHorizontal />
            <Button type="button" onClick={() => goBack('/projects')}>
              Avbryt
            </Button>
          </Row>
        </Form>
        <SpacerVertical />
      </Block>
    </Col>
  )
}

export default connect('user, isUpdatingUser, isNarrow')(Profile)
