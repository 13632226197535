import { h } from 'preact'
import { connect } from 'unistore/preact'
import { BasicDialog } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { deletePoint } from '../../actions'

const DeletePoint = ({ project, pointId, isDeletingPoint, onDelete }) => {
  const onClose = () => goBack(`/projects/${project.id}`)

  const handleSubmit = async () => {
    if (await deletePoint(pointId)) {
      if (onDelete) {
        onDelete()
      } else {
        onClose()
      }
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      title="Ta bort punkt"
      primaryActionText="Ta bort"
      primaryActionLoading={isDeletingPoint}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      Vill du ta punkten?
    </BasicDialog>
  )
}

export default connect('project, isDeletingPoint')(DeletePoint)
