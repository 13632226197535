import { store } from '../store'
import { del, get, patch, post } from '../api'
import { removeItem, setItem } from '../storage'
import { routeTo } from '@sodra/prutt'

export * from './projects'
export * from './points'
export * from './photos'

export const login = async ({ email, password }) => {
  try {
    store.setState({ isLoggingIn: true })
    const {
      data: { token, user }
    } = await post('/login', { email, password })
    setItem('token', token)
    store.setState({ user, isLoggingIn: false })
    return user
  } catch (error) {
    store.setState({ error, isLoggingIn: false })
  }
}

export const logout = () => {
  removeItem('token')
  routeTo('/')
  store.setState({ user: null })
  return true
}

export const forgotPassword = async (params) => {
  try {
    store.setState({ isForgettingPassword: true })
    await post('/forgot-password', params)
    store.setState({ isForgettingPassword: false })
    return true
  } catch (error) {
    store.setState({ error, isForgettingPassword: false })
  }
}

export const resetPassword = async (params) => {
  try {
    store.setState({ isResettingPassword: true })
    await post('/reset-password', params)
    store.setState({ isResettingPassword: false })
    return true
  } catch (error) {
    store.setState({ error, isResettingPassword: false })
  }
}

export const createUser = async (params) => {
  try {
    store.setState({ isCreatingUser: true })
    await post('/users', params)
    store.setState({ isCreatingUser: false })
    return true
  } catch (error) {
    store.setState({ error, isCreatingUser: false })
  }
}

export const updateUser = async (params) => {
  try {
    store.setState({ isUpdatingUser: true })
    const userId = store.getState().user.id
    const { data: user } = await patch(`/users/${userId}`, params)
    store.setState({ user, isUpdatingUser: false })
    return true
  } catch (error) {
    store.setState({ error, isUpdatingUser: false })
  }
}

export const updateOtherUser = async (userId, params) => {
  try {
    store.setState({ isUpdatingUser: true })
    await patch(`/users/${userId}`, params)
    store.setState({ isUpdatingUser: false })
    return true
  } catch (error) {
    store.setState({ error, isUpdatingUser: false })
  }
}

export const verifyUser = async (userId) => {
  try {
    store.setState({ isVerifyingUser: true })
    await post(`/users/${userId}/verify`)
    store.setState({ isVerifyingUser: false })
    return true
  } catch (error) {
    store.setState({ error, isVerifyingUser: false })
  }
}

export const deleteUser = async (userId) => {
  try {
    store.setState({ isDeletingUser: true })
    await del(`/users/${userId}`)
    store.setState({ isDeletingUser: false })
    return true
  } catch (error) {
    store.setState({ error, isDeletingUser: false })
  }
}

export const setError = (error) => store.setState({ error })

export const clearError = () => setError(null)

export const setUserLocation = (userLocation) => store.setState({ userLocation })

export const clearUserLocation = () => setUserLocation(undefined)

const sleep = async (millis) => new Promise((resolve) => setTimeout(resolve, millis))

export const download = async (filename, params) => {
  store.setState({ isDownloading: true })
  try {
    const { data: exp } = await post(`/exports`, params)
    for (let i = 0; i < 500; i++) {
      const {
        data: { error, finalUri }
      } = await get(`/exports/${exp.id}`)
      if (error) {
        throw new Error(error)
      }
      if (finalUri) {
        location.href = `${process.env.API_URL}/v1/download?url=${finalUri}&filename=${filename}`
        store.setState({ isDownloading: false })
        return true
      }
      await sleep(5000)
    }
    throw new Error('Timeout error')
  } catch (error) {
    store.setState({ error, isDownloading: false })
  }
}

/*
export const download = async (filename, params) => {
  store.setState({ isDownloading: true })
  try {
    const { data: url } = await post('/export', params)
    const blob = await fetch(url).then((res) => res.blob())
    const a = document.createElement('a')
    a.download = filename
    a.href = window.URL.createObjectURL(blob)
    document.body.appendChild(a)
    a.click()
    a.remove()
    store.setState({ isDownloading: false })
    return true
  } catch (error) {
    store.setState({ error, isDownloading: false })
  }
}
*/
