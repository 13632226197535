import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Block, Col, Row } from 'jsxstyle/preact'
import {
  AddIcon,
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  Divider,
  IconButton,
  List,
  ListItem,
  SpacerVertical
} from '@sodra/bongo-ui'
import { Route, routeTo, Switch } from '@sodra/prutt'

import { get } from '../../api'
import { setError } from '../../actions'
import { useLocalStorageState } from '../../use-local-storage-state'

import Header from './Header'
import LeftMenu from './LeftMenu'
import CreateUser from './CreateUser'
import UserDetails from './UserDetails'

const Paginator = ({ offset, limit, total, setOffset }) => {
  const pages = Math.ceil(total / limit)
  const page = Math.floor(offset / limit) + 1
  return (
    <Row alignItems="center" justifyContent="flex-end">
      <IconButton
        icon={ChevronLeftIcon}
        disabled={page === 1}
        onClick={() => setOffset(offset - limit)}
      />
      Sida {page} av {pages}
      <IconButton
        icon={ChevronRightIcon}
        disabled={page === pages}
        onClick={() => setOffset(offset + limit)}
      />
    </Row>
  )
}

const Users = () => {
  const [query, setQuery] = useLocalStorageState('fotoapp:users:query', '')
  const [offset, setOffset] = useLocalStorageState('fotoapp:users:offset', 0)
  const limit = 10

  const [menuVisible, setMenuVisible] = useState(false)
  const [users, setUsers] = useState([])
  const [total, setTotal] = useState(0)

  const [refetch, setRefetch] = useState(0)

  useEffect(() => {
    setOffset(0)
  }, [query])

  useEffect(() => {
    let cancelled = false
    get(`/users`, { query, offset, limit })
      .then((res) => {
        if (!cancelled) {
          setTotal(res.meta.total)
          setUsers(res.data)
        }
      })
      .catch((err) => {
        if (!cancelled) {
          setError(err)
        }
      })
    return () => (cancelled = true)
  }, [query, offset, limit, refetch])

  const handleClick = (project) => {
    console.log('click')
  }

  return (
    <Col
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      overflow="auto"
      background="var(--surface)"
    >
      <Header onShowMenu={() => setMenuVisible(true)} query={query} setQuery={setQuery} />
      <Block flex="1" overflow="auto">
        <SpacerVertical />
        <Block width="900px" maxWidth="calc(100% - 40px)" margin="auto">
          <Row alignItems="center">
            <Block>Användare</Block>
            <Block flex="1" />
            <Button small icon={AddIcon} onClick={() => routeTo(`/users/create-user`)}>
              Ny användare
            </Button>
          </Row>
          <SpacerVertical small />
          <Divider />
          <SpacerVertical />
          <List>
            {users.map((user) => {
              return (
                <ListItem
                  text={user.name}
                  secondaryText={user.email}
                  metaText={user.isAdmin ? 'ADMIN' : ''}
                  onClick={() => routeTo(`/users/${user.id}`)}
                />
              )
            })}
          </List>
          {total > limit && (
            <Fragment>
              <SpacerVertical />
              <Divider />
              <SpacerVertical />
              <Paginator offset={offset} limit={limit} total={total} setOffset={setOffset} />
            </Fragment>
          )}
          <SpacerVertical />
          <Switch>
            <Route
              path="/users/create-user"
              render={() => <CreateUser onUserCreated={() => setRefetch((value) => value + 1)} />}
            />
            <Route
              path="/users/:userId"
              render={({ userId }) => {
                const user = users.find((user) => user.id === userId)
                if (user) {
                  return (
                    <UserDetails
                      user={user}
                      onUserUpdated={() => setRefetch((value) => value + 1)}
                    />
                  )
                }
              }}
            />
          </Switch>
        </Block>
      </Block>
      {menuVisible && <LeftMenu onClose={() => setMenuVisible(false)} />}
    </Col>
  )
}

export default connect('user')(Users)
