import { h, Fragment } from 'preact'
import { useRef } from 'preact/hooks'
import dateFormat from 'dateformat'
import { Block, Col, Row } from 'jsxstyle/preact'
import { connect } from 'unistore/preact'

import { AddAPhotoIcon, Button, SpacerHorizontal, SpacerVertical } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'
import { useMedia } from '@sodra/use-media'

import PhotosPreview from './PhotosPreview'

const PointPreviewOnMobile = ({ point, project, isUploadingPhoto, onFileUpload, isNarrow }) => {
  const supportsHover = useMedia(['(hover: hover)'], [true], false)
  const fileInput = useRef()

  const handleFileUpload = async () => {
    const file = fileInput.current.files[0]
    if (file) {
      onFileUpload(file)
    }
  }

  return (
    <Block
      position="absolute"
      maxWidth={isNarrow ? undefined : '400px'}
      margin={isNarrow ? undefined : '0 auto'}
      left="0"
      right="0"
      background="var(--surface)"
      color="var(--on-surface)"
      overflow="auto"
      boxShadow="0 -1px 3px var(--box-shadow-color)"
      height="380px"
      bottom={point ? 0 : '-400px'}
      transition="bottom .18s cubic-bezier(0, 0, .2, 1)"
      willChange="bottom"
    >
      {point && (
        <Fragment>
          <PhotosPreview
            height="50%"
            point={point}
            projectId={project.id}
            onClickUpload={() => fileInput.current.click()}
          />
          <Col height="50%" justifyContent="space-between">
            <Block padding="10px 15px">
              <Row margin="5px 0" overflow="hidden" alignItems="baseline">
                <Block>Skapad:</Block>
                <SpacerHorizontal tiny />
                <Block
                  fontWeight="bold"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {dateFormat('yyyy-mm-dd HH:MM', point.created)}
                </Block>
                <SpacerVertical tiny />
              </Row>
              <Row margin="5px 0" overflow="hidden" alignItems="baseline">
                <Block>Skapad av:</Block>
                <SpacerHorizontal tiny />
                <Block
                  fontWeight="bold"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {point.user.name}
                </Block>
                <SpacerVertical tiny />
              </Row>
              {project.attributes.slice(0, 3).map(({ id, name, type, numDecimals }) => {
                const attribute = point.attributes.find((attribute) => attribute.id === id)
                const value = attribute ? attribute.value : ''
                let formattedValue
                if (type === 'number') {
                  formattedValue = value.trim() === '' ? '' : parseFloat(value).toFixed(numDecimals)
                } else if (type === 'boolean') {
                  formattedValue = value === 'TRUE' ? 'Ja' : 'Nej'
                } else {
                  formattedValue = value
                }
                return (
                  <Row margin="5px 0" overflow="hidden" alignItems="baseline">
                    <Block>{name}:</Block>
                    <SpacerHorizontal tiny />
                    <Block
                      fontWeight="bold"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {formattedValue}
                    </Block>
                    <SpacerVertical tiny />
                  </Row>
                )
              })}
            </Block>
            <Row alignItems="center" padding="0 15px 15px">
              <Button
                contained
                small={supportsHover}
                onClick={() => routeTo(`/projects/${project.id}/points/${point.id}`)}
              >
                Öppna
              </Button>
              <SpacerHorizontal tiny />
              <Button
                outlined
                small={supportsHover}
                onClick={() => routeTo(`/projects/${project.id}/points/${point.id}/edit-location`)}
              >
                Flytta
              </Button>
              <SpacerHorizontal tiny />
              <Button
                outlined
                small={supportsHover}
                icon={AddAPhotoIcon}
                onClick={() => fileInput.current.click()}
                loading={isUploadingPhoto}
              >
                Lägg till foto
              </Button>
            </Row>
          </Col>
          <input
            style={{ display: 'none' }}
            ref={fileInput}
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
          />
        </Fragment>
      )}
    </Block>
  )
}

export default connect('project, isUploadingPhoto, isNarrow')(PointPreviewOnMobile)
