import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { BasicDialog, Checkbox, Form, SpacerVertical, TextField } from '@sodra/bongo-ui'

import { goBack } from '@sodra/prutt'

import { updateOtherUser, deleteUser } from '../../actions'

const UserDetails = ({ user, isUpdatingUser, isNarrow, onUserUpdated }) => {
  const [name, setName] = useState(user.name)
  const [email, setEmail] = useState(user.email)
  const [password, setPassword] = useState()
  const [isAdmin, setIsAdmin] = useState(user.isAdmin)

  const onClose = () => {
    goBack(`/users`)
  }

  const handleDelete = async () => {
    if (confirm('Är du säker?')) {
      if (await deleteUser(user.id)) {
        if (onUserUpdated) {
          onUserUpdated()
        }
        onClose()
      }
    }
  }

  const handleSubmit = async () => {
    if (await updateOtherUser(user.id, { name, email, password, isAdmin })) {
      if (onUserUpdated) {
        onUserUpdated()
      }
      onClose()
    }
  }

  return (
    <BasicDialog
      full={isNarrow}
      disableAutofocus
      title={user.name}
      primaryActionText="Spara"
      primaryActionLoading={isUpdatingUser}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
      secondaryActionText="Ta bort"
      onSecondaryActionClick={handleDelete}
    >
      <Form onSubmit={handleSubmit}>
        <TextField width="100%" label="Namn" value={name} onInput={setName} />
        <SpacerVertical />
        <TextField width="100%" label="Epost" value={email} onInput={setEmail} />
        <SpacerVertical />
        <TextField
          type="password"
          width="100%"
          label="Lösenord"
          value={password}
          onInput={setPassword}
        />
        <SpacerVertical />
        <Checkbox label="Admin" checked={isAdmin} onChange={setIsAdmin} />
      </Form>
    </BasicDialog>
  )
}

export default connect('isUpdatingUser, isNarrow')(UserDetails)
