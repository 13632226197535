import { h } from 'preact'
import { connect } from 'unistore/preact'
import { BasicDialog, DeleteIcon, List, ListItem, SpacerVertical } from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { formatAttribute } from '../../attributes'

import CreateAttribute from './CreateAttribute'
import EditAttribute from './EditAttribute'
import DeleteAttribute from './DeleteAttribute'
import SettingsTabs from '../SettingsTabs'

const Attributes = ({ project, isNarrow }) => {
  const onClose = () => goBack(`/projects/${project.id}`)

  return (
    <BasicDialog
      large={!isNarrow}
      full={isNarrow}
      flexGrowContent={isNarrow}
      disableAutofocus
      title="Inställningar"
      action1Text="Stäng"
      onAction1Click={onClose}
      secondaryActionText="Nytt attribut"
      secondaryActionStyle="outlined"
      onSecondaryActionClick={() => routeTo(`/projects/${project.id}/attributes/create-attribute`)}
      onClose={onClose}
    >
      <SettingsTabs active={'attributes'} />
      <SpacerVertical />
      <List>
        {project.attributes.map((attribute) => {
          return (
            <ListItem
              text={attribute.name}
              secondaryText={
                formatAttribute(attribute.type) +
                (attribute.type === 'number'
                  ? ` (${attribute.numDecimals} decimal${attribute.numDecimals !== 1 ? 'er' : ''})`
                  : '')
              }
              onClick={() =>
                routeTo(`/projects/${project.id}/attributes/edit-attribute/${attribute.id}`)
              }
              actionIcon={DeleteIcon}
              onActionClick={() =>
                routeTo(`/projects/${project.id}/attributes/delete-attribute/${attribute.id}`)
              }
            />
          )
        })}
      </List>
      <Route path="/projects/:projectId/attributes/create-attribute" component={CreateAttribute} />
      <Route
        path="/projects/:projectId/attributes/edit-attribute/:attributeId"
        component={EditAttribute}
      />
      <Route
        path="/projects/:projectId/attributes/delete-attribute/:attributeId"
        component={DeleteAttribute}
      />
    </BasicDialog>
  )
}

export default connect('project, isNarrow')(Attributes)
