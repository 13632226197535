import { h } from 'preact'
import { connect } from 'unistore/preact'
import { BasicDialog } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { deleteMember } from '../../../actions'

const DeleteMember = ({ project, userId, isDeletingMember }) => {
  const user = project.members.find((user) => user.id === userId)

  if (!user) {
    return null
  }

  const onClose = () => goBack(`/projects/${project.id}/members`)

  const handleDelete = async () => {
    if (await deleteMember(userId)) {
      onClose()
    }
  }

  return (
    <BasicDialog
      title="Ta bort medlem"
      primaryActionText="Ta bort"
      primaryActionLoading={isDeletingMember}
      onPrimaryActionClick={handleDelete}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      Ta bort <strong>{user.name}</strong> från {project.name}?
    </BasicDialog>
  )
}

export default connect('project, isDeletingMember')(DeleteMember)
