import { Fragment, h } from 'preact'
import { parse } from 'querystring'
import { useEffect, useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Button, ProgressCircular, SpacerVertical } from '@sodra/bongo-ui'
import { Block, Col, Row } from 'jsxstyle/preact'

import { setError, verifyUser } from '../actions'

import logo from 'url:../logo.svg'
import { routeTo } from '@sodra/prutt'

const Verify = ({ isVerifyingUser }) => {
  const [isVerified, setIsVerified] = useState(false)

  useEffect(() => {
    try {
      const { token } = parse(location.search.slice(1))
      const userId = Buffer.from(token, 'base64').toString()
      verifyUser(userId).then(() => setIsVerified(true))
    } catch (err) {
      setError(err)
    }
  }, [])

  return (
    <Row
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      alignItems="center"
      justifyContent="center"
      background="var(--surface)"
    >
      <Col alignItems="center">
        <img src={logo} width="100" />
        <SpacerVertical large />
        {isVerifyingUser && <ProgressCircular />}
        {isVerified && (
          <Fragment>
            <Block>Epost verifierad! Logga in för att fortsätta.</Block>
            <SpacerVertical />
            <Button contained onClick={() => routeTo(`/`, true)}>
              Logga in
            </Button>
          </Fragment>
        )}
      </Col>
    </Row>
  )
}

export default connect('isVerifyingUser, error')(Verify)
