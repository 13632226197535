import { on, off, fire } from '../../events'

const styles = [
  {
    title: 'Karta',
    uri: 'mapbox://styles/mapbox/outdoors-v11'
  },
  {
    title: 'Satellit',
    uri: 'mapbox://styles/mapbox/satellite-v9'
  }
]

const divider = () => {
  const div = document.createElement('div')
  div.style.borderTop = 'solid 1px #eee'
  return div
}

export class StyleSwitcher {
  constructor(layers = [], styleUri, visibleLayers = []) {
    this.layers = layers
    this.styleUri = styleUri
    this.visibleLayers = visibleLayers
    this.onLayersChanged = this.onLayersChanged.bind(this)
    this.onVisibleLayersChanged = this.onVisibleLayersChanged.bind(this)
    this.onMapStyleChanged = this.onMapStyleChanged.bind(this)
    this.onStyleLoaded = this.onStyleLoaded.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  onVisibleLayersChanged(visibleLayers) {
    this.visibleLayers = visibleLayers
    for (let layer of this.layers) {
      if (this.visibleLayers.includes(layer.id)) {
        this.map.setLayoutProperty(layer.id, 'visibility', 'visible')
        if (layer.type === 'Polygon' || layer.type === 'MultiPolygon') {
          this.map.setLayoutProperty(`${layer.id}-line`, 'visibility', 'visible')
        }
      } else {
        this.map.setLayoutProperty(layer.id, 'visibility', 'none')
        if (layer.type === 'Polygon' || layer.type === 'MultiPolygon') {
          this.map.setLayoutProperty(`${layer.id}-line`, 'visibility', 'none')
        }
      }
    }
    this.drawLayers()
  }

  onMapStyleChanged(style) {
    this.styleUri = style.uri
    this.map.setStyle(style.uri)
    this.drawStyles()
  }

  onLayersChanged(layers) {
    let updated = false
    for (let layer of layers) {
      const exists = this.layers.some((l) => l.id === layer.id)
      if (!exists) {
        //console.log('Add layer', layer.name)
        this.addLayer(layer)
        updated = true
      }
    }

    for (let layer of this.layers) {
      const exists = layers.some((l) => l.id === layer.id)
      if (!exists) {
        //console.log('Remove layer', layer.name)
        this.removeLayer(layer)
        updated = true
      }
    }

    this.layers = layers

    if (updated) {
      this.drawLayers()
    }
  }

  onMouseEnter() {
    this.map.getCanvas().style.cursor = 'pointer'
  }

  onMouseLeave() {
    this.map.getCanvas().style.cursor = ''
  }

  onClick(e) {
    const feature = e.features[0]
    const props = Object.entries(feature.properties)
    let html = '<div class="feature-popup">'
    if (props.length === 0) {
      html += 'Attribut saknas'
    }
    for (let [key, value] of props) {
      html += `
          <strong>${key}</strong>
          <div>${value}</div>
        `
    }
    html += `</div>`
    new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(html).addTo(this.map)
  }

  addLayer(layer) {
    //console.log('Add layer', layer)

    this.map.addSource(layer.id, {
      type: 'geojson',
      data: layer.url,
      generateId: true
    })

    const mapStyle = this.map.getStyle()

    if (layer.type === 'Polygon' || layer.type === 'MultiPolygon') {
      let fillColor = '#888'
      let lineColor = '#FFF'
      if (mapStyle.name === 'Mapbox Satellite') {
        fillColor = '#FFF'
        lineColor = '#000'
      }

      console.log(`Adding ${layer.type} layer`, layer.url)
      this.map.addLayer({
        id: layer.id,
        type: 'fill',
        source: layer.id,
        layout: {
          visibility: this.visibleLayers.includes(layer.id) ? 'visible' : 'none'
        },
        paint: {
          'fill-color': fillColor,
          'fill-opacity': 0.4
        }
      })
      this.map.addLayer({
        id: `${layer.id}-line`,
        type: 'line',
        source: layer.id,
        layout: {
          visibility: this.visibleLayers.includes(layer.id) ? 'visible' : 'none'
        },
        paint: {
          'line-color': lineColor,
          'line-width': 1
        }
      })
    }

    if (layer.type === 'LineString' || layer.type === 'MultiLineString') {
      let lineColor = '#888'
      if (mapStyle.name === 'Mapbox Satellite') {
        lineColor = '#FFF'
      }

      console.log('Adding linestring layer', layer.url)
      this.map.addLayer({
        id: layer.id,
        type: 'line',
        source: layer.id,
        layout: {
          visibility: this.visibleLayers.includes(layer.id) ? 'visible' : 'none',
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': lineColor,
          'line-width': 2
        }
      })
    }

    if (layer.type === 'Point') {
      let circleColor = '#888'
      let strokeColor = '#FFF'
      if (mapStyle.name === 'Mapbox Satellite') {
        circleColor = '#888'
        strokeColor = '#000'
      }

      this.map.addLayer({
        id: layer.id,
        type: 'circle',
        source: layer.id,
        layout: {
          visibility: this.visibleLayers.includes(layer.id) ? 'visible' : 'none'
        },
        paint: {
          'circle-radius': 5,
          'circle-stroke-width': 1,
          'circle-color': circleColor,
          'circle-stroke-color': strokeColor
        }
      })
    }

    this.map.on('mouseenter', layer.id, this.onMouseEnter)

    this.map.on('mouseleave', layer.id, this.onMouseLeave)

    this.map.on('click', layer.id, this.onClick)
  }

  removeLayer(layer) {
    this.map.removeLayer(layer.id)
    if (layer.type === 'Polygon' || layer.type === 'MultiPolygon') {
      this.map.removeLayer(`${layer.id}-line`)
    }
    this.map.removeSource(layer.id)
    this.map.off('mouseenter', layer.id, this.onMouseEnter)
    this.map.off('mouseleave', layer.id, this.onMouseLeave)
    this.map.off('click', layer.id, this.onClick)
  }

  onStyleLoaded() {
    console.log('style loaded')
    for (let layer of this.layers) {
      this.addLayer(layer)
    }
  }

  drawLayers() {
    this.overlaysContainer.textContent = ''
    for (let layer of this.layers) {
      const layerButton = document.createElement('button')
      layerButton.type = 'button'
      layerButton.innerText = layer.name
      if (this.visibleLayers.includes(layer.id)) {
        layerButton.classList.add('active')
      }
      layerButton.addEventListener('click', () => {
        const mapboxLayer = this.map.getLayer(layer.id)
        if (!mapboxLayer) {
          console.warn('Layer does not exist in map', layer.id)
          return
        }
        const visibility = this.map.getLayoutProperty(layer.id, 'visibility')
        const visibleLayers =
          visibility === 'visible'
            ? this.visibleLayers.filter((layerId) => layerId !== layer.id)
            : [...this.visibleLayers, layer.id]
        fire('visibleLayersChanged', visibleLayers)
      })
      this.overlaysContainer.appendChild(layerButton)
    }
  }

  drawStyles() {
    this.styleContainer.textContent = ''
    for (let style of styles) {
      const styleButton = document.createElement('button')
      styleButton.type = 'button'
      styleButton.innerText = style.title
      styleButton.addEventListener('click', () => {
        if (styleButton.classList.contains('active')) {
          return
        }
        // const [activeButton] = this.styleContainer.getElementsByClassName('active')
        // if (activeButton) {
        //   activeButton.classList.remove('active')
        // }
        // styleButton.classList.add('active')
        fire('mapStyleChanged', style)
      })
      if (style.uri === this.styleUri) {
        styleButton.classList.add('active')
      }
      this.styleContainer.appendChild(styleButton)
    }
  }

  onAdd(map) {
    this.map = map

    if (map.isStyleLoaded()) {
      for (let layer of this.layers) {
        this.addLayer(layer)
      }
    }

    this.container = document.createElement('div')
    this.container.classList.add('mapboxgl-ctrl')
    this.container.classList.add('mapboxgl-ctrl-group')

    const minimizedButton = document.createElement('button')
    minimizedButton.type = 'button'
    minimizedButton.classList.add('mapboxgl-ctrl-icon')
    minimizedButton.classList.add('mapboxgl-style-switcher')
    minimizedButton.addEventListener('click', () => {
      minimizedButton.style.display = 'none'
      this.maximizedContainer.style.display = 'block'
      map.once('click', () => {
        minimizedButton.style.display = 'block'
        this.maximizedContainer.style.display = 'none'
      })
    })

    this.styleContainer = document.createElement('div')
    this.styleContainer.classList.add('mapboxgl-style-list')
    this.styleContainer.style.display = 'block'

    this.overlaysContainer = document.createElement('div')
    this.overlaysContainer.classList.add('mapboxgl-style-list')
    this.overlaysContainer.style.display = 'block'

    this.maximizedContainer = document.createElement('div')
    this.maximizedContainer.style.display = 'none'
    this.maximizedContainer.appendChild(this.styleContainer)
    this.maximizedContainer.appendChild(divider())
    this.maximizedContainer.appendChild(this.overlaysContainer)

    this.container.appendChild(this.maximizedContainer)
    this.container.appendChild(minimizedButton)

    this.drawStyles()
    this.drawLayers()

    on('layerschanged', this.onLayersChanged)
    on('visibleLayersChanged', this.onVisibleLayersChanged)
    on('mapStyleChanged', this.onMapStyleChanged)
    map.on('style.load', this.onStyleLoaded)

    return this.container
  }

  onRemove() {
    this.map.off('style.load', this.onStyleLoaded)
    off('layerschanged', this.onLayersChanged)
    off('visibleLayersChanged', this.onVisibleLayersChanged)
    off('mapStyleChanged', this.onMapStyleChanged)
    for (let layer of this.layers) {
      this.removeLayer(layer)
    }
    this.container.parentNode.removeChild(this.container)
    this.map = undefined
  }
}
