import { Fragment, h } from 'preact'
import { connect } from 'unistore/preact'

import { BasicDialog, H2, List, ListItem, SpacerVertical } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

const ShowProps = ({ project, props, isNarrow, onSelect }) => {
  const onClose = () => goBack(`/projects/${project.id}/download`)

  const predefinedProps = props.filter((prop) => prop.source === 'predefined')
  const pointAttributeProps = props.filter((prop) => prop.source === 'point-attribute')

  const layerAttributes = {}
  for (let prop of props) {
    const { source, key, value } = prop
    if (source !== 'predefined' && source !== 'point-attribute') {
      if (!layerAttributes[source]) {
        layerAttributes[source] = []
      }
      layerAttributes[source].push({ key, value })
    }
  }

  return (
    <BasicDialog
      full={isNarrow}
      large={!isNarrow}
      disableAutofocus
      title="Infoga attribut"
      action1Text="Stäng"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <List>
        {predefinedProps.map(({ key, value }) => {
          return <ListItem text={key} secondaryText={value} onClick={() => onSelect(key)} />
        })}
        {pointAttributeProps.map(({ key, value }) => {
          return <ListItem text={key} secondaryText={value} onClick={() => onSelect(key)} />
        })}
      </List>
      {Object.entries(layerAttributes).map(([layerName, props]) => {
        return (
          <Fragment>
            <SpacerVertical />
            <H2 size="6">Attribut från lager {layerName}</H2>
            <SpacerVertical />
            <List>
              {props.map(({ key, value }) => {
                return (
                  <ListItem
                    text={key + ' (' + layerName + ')'}
                    secondaryText={value}
                    onClick={() => onSelect(key)}
                  />
                )
              })}
            </List>
          </Fragment>
        )
      })}
    </BasicDialog>
  )
}

export default connect('project, isNarrow')(ShowProps)
