import { h } from 'preact'
import { connect } from 'unistore/preact'
import { BasicDialog } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { deleteInvitation } from '../../../actions'

const DeleteInvitation = ({ project, invitationId, isDeletingInvitation }) => {
  const inv = project.invitations.find((inv) => inv.id === invitationId)

  if (!inv) {
    return null
  }

  const onClose = () => goBack(`/projects/${project.id}/members`)

  const handleDelete = async () => {
    if (await deleteInvitation(invitationId)) {
      onClose()
    }
  }

  return (
    <BasicDialog
      title="Ta bort inbjudan"
      primaryActionText="Ta bort"
      primaryActionLoading={isDeletingInvitation}
      onPrimaryActionClick={handleDelete}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      Ta bort inbjudan till <strong>{inv.email}</strong>?
    </BasicDialog>
  )
}

export default connect('project, isDeletingInvitation')(DeleteInvitation)
