import { h } from 'preact'
import { connect } from 'unistore/preact'
import { BasicDialog } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { deleteLayer } from '../../../actions'

const DeleteLayer = ({ project, layerId, isUpdatingProject }) => {
  const layer = project.layers.find((layer) => layer.id === layerId)

  const handleSubmit = async () => {
    if (await deleteLayer(layer.id)) {
      onClose()
    }
  }

  const onClose = () => goBack(`/projects/${project.id}/layers`)

  if (!layer) {
    return null
  }

  return (
    <BasicDialog
      disableAutofocus
      title="Ta bort lager"
      primaryActionText="Ta bort"
      primaryActionLoading={isUpdatingProject}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      Vill du ta bort <strong>{layer.name}</strong>?
    </BasicDialog>
  )
}

export default connect('project, isUpdatingProject')(DeleteLayer)
