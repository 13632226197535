import { stringify } from 'querystring'
import { getItem } from './storage'

const doFetch = async (method, endpoint, body, params) => {
  let url = process.env.API_URL + '/v1' + endpoint
  if (params) {
    url = url + '?' + stringify(params)
  }

  const res = await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getItem('token')
    },
    body: body ? JSON.stringify(body) : null
  })

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.error)
  }

  return json
}

export const postFormData = async (endpoint, formData) => {
  const url = process.env.API_URL + '/v1' + endpoint

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + getItem('token')
    },
    body: formData
  })

  const json = await res.json()

  if (!res.ok) {
    throw new Error(json.error)
  }

  return json
}

export const get = (url, params) => doFetch('GET', url, null, params)
export const post = (url, data) => doFetch('POST', url, data)
export const patch = (url, data) => doFetch('PATCH', url, data)
export const put = (url, data) => doFetch('PUT', url, data)
export const del = (url, data) => doFetch('DELETE', url, data)
