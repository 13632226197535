import { Fragment, h } from 'preact'
import { connect } from 'unistore/preact'
import { BasicDialog, Checkbox, Form, SpacerVertical, TextArea, TextField } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'
import { useState } from 'preact/hooks'

import { updatePoint } from '../../../actions'

const EditAttributes = ({ project, point, isUpdatingPoint, isNarrow }) => {
  const [attributes, setAttributes] = useState(point.attributes)

  const setAttribute = (id, value) => {
    setAttributes((attributes) => {
      if (attributes.some((a) => a.id === id)) {
        return attributes.map((a) => (a.id === id ? { id, value } : a))
      } else {
        return [...attributes, { id, value }]
      }
    })
  }

  const handleSubmit = async () => {
    if (await updatePoint({ attributes })) {
      onClose()
    }
  }

  const onClose = () => goBack(`/projects/${project.id}/points/${point.id}`)

  return (
    <BasicDialog
      large={!isNarrow}
      full={isNarrow}
      flexGrowContent={isNarrow}
      disableAutofocus
      title="Ändra attribut"
      primaryActionText="Spara"
      primaryActionLoading={isUpdatingPoint}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        {project.attributes.map(({ id, name, type }) => {
          const attribute = attributes.find((a) => a.id === id)
          const value = attribute ? attribute.value : ''
          return (
            <Fragment>
              {type === 'text' && (
                <TextArea
                  autoHeight
                  maxRows={4}
                  width="100%"
                  label={name}
                  value={value}
                  onInput={(value) => setAttribute(id, value)}
                />
              )}
              {type === 'number' && (
                <TextField
                  type="number"
                  width="100%"
                  label={name}
                  value={value}
                  onInput={(value) => setAttribute(id, value)}
                />
              )}
              {type === 'boolean' && (
                <Checkbox
                  label={name}
                  checked={value === 'TRUE'}
                  onChange={(checked) => setAttribute(id, checked ? 'TRUE' : 'FALSE')}
                />
              )}
              {type === 'date' && (
                <TextField
                  type="date"
                  width="100%"
                  label={name}
                  value={value}
                  onInput={(value) => setAttribute(id, value)}
                />
              )}
              <SpacerVertical />
            </Fragment>
          )
        })}
      </Form>
    </BasicDialog>
  )
}

export default connect('project, point, isUpdatingPoint, isNarrow')(EditAttributes)
