import { h } from 'preact'
import { connect } from 'unistore/preact'
import { BasicDialog } from '@sodra/bongo-ui'

import { goBack } from '@sodra/prutt'

import { createRequest } from '../../actions'

const RequestAccess = ({ project, isCreatingRequest }) => {
  const onClose = () => {
    goBack(`/projects`)
  }

  const handleSubmit = async () => {
    if (await createRequest(project.id)) {
      goBack('/projects')
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      title="Stängt projekt"
      primaryActionText="Begär access"
      primaryActionLoading={isCreatingRequest}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      Projektet är endast öppet för medlemmar.
      <br /> <br />
      Vill du begära access till <strong>{project.name}</strong>?
    </BasicDialog>
  )
}

export default connect('isCreatingRequest')(RequestAccess)
