import { h } from 'preact'
import { connect } from 'unistore/preact'
import { BasicDialog, DeleteIcon, List, ListItem, SpacerVertical } from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import CreateLayer from './CreateLayer'
import DeleteLayer from './DeleteLayer'
import SettingsTabs from '../SettingsTabs'

const Layers = ({ project, isNarrow }) => {
  const onClose = () => goBack(`/projects/${project.id}`)

  return (
    <BasicDialog
      large={!isNarrow}
      full={isNarrow}
      flexGrowContent={isNarrow}
      disableAutofocus
      title="Inställningar"
      action1Text="Stäng"
      onAction1Click={onClose}
      secondaryActionText="Nytt lager"
      secondaryActionStyle="outlined"
      onSecondaryActionClick={() => routeTo(`/projects/${project.id}/layers/create-layer`)}
      onClose={onClose}
    >
      <SettingsTabs active={'layers'} />
      <SpacerVertical />
      <List>
        {project.layers.map((layer) => {
          return (
            <ListItem
              text={layer.name}
              secondaryText={layer.type}
              onClick={() => window.open(layer.url)}
              actionIcon={DeleteIcon}
              onActionClick={() =>
                routeTo(`/projects/${project.id}/layers/delete-layer/${layer.id}`)
              }
            />
          )
        })}
      </List>
      <Route path="/projects/:projectId/layers/create-layer" component={CreateLayer} />
      <Route path="/projects/:projectId/layers/delete-layer/:layerId" component={DeleteLayer} />
    </BasicDialog>
  )
}

export default connect('project, isNarrow')(Layers)
