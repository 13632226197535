import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { Block, Row } from 'jsxstyle/preact'
import { BasicDialog, ProgressCircular } from '@sodra/bongo-ui'
import { Redirect, Route, Router, Switch } from '@sodra/prutt'

import { getItem } from '../storage'
import { get } from '../api'
import { store } from '../store'
import { clearError } from '../actions'

import Welcome from './Welcome'
import Register from './Register'
import Verify from './Verify'
import NotLoggedIn from './NotLoggedIn'
import Projects from './Projects'
import Users from './Users'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'

const App = ({ user, error }) => {
  const token = getItem('token')
  const [isCheckingToken, setIsCheckingToken] = useState(token !== null)

  useEffect(() => {
    if (token) {
      get('/me')
        .then(({ data: user }) => store.setState({ user }))
        .finally(() => setIsCheckingToken(false))
    }
  }, [token])

  useEffect(() => {
    const handleResize = () => {
      store.setState({ isNarrow: window.innerWidth < 700, isWide: window.innerWidth >= 1100 })
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (isCheckingToken) {
    console.log('Checking token...')
    return (
      <Row
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        alignItems="center"
        justifyContent="center"
      >
        <ProgressCircular />
      </Row>
    )
  }

  return (
    <Block
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      overflow="hidden"
      background="var(--surface)"
    >
      <Router>
        <Switch>
          <Route path="/register" component={Register} />
          <Route path="/verify" component={Verify} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route
            path="/welcome"
            render={(props) => (user ? <Welcome {...props} /> : <NotLoggedIn />)}
          />
          <Route
            path="/projects"
            render={(props) => (user ? <Projects {...props} /> : <NotLoggedIn />)}
          />
          <Route
            path="/users"
            render={(props) => (user && user.isAdmin ? <Users {...props} /> : <NotLoggedIn />)}
          />
          <Redirect to="/welcome" />
        </Switch>
      </Router>
      {error && (
        <BasicDialog
          title="Hoppsan…"
          onClose={clearError}
          action1Text="Stäng"
          onAction1Click={clearError}
        >
          {error.message}
        </BasicDialog>
      )}
    </Block>
  )
}

export default connect('user, isNarrow, error')(App)
