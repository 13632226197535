import { store } from '../store'
import { del, patch, post } from '../api'

const incRefreshVersion = () => {
  const refreshVersion = store.getState().refreshVersion || 0
  store.setState({ refreshVersion: refreshVersion + 1 })
}

export const uploadPhoto = async ({ file }) => {
  try {
    store.setState({ isUploadingPhoto: true })

    const ext =
      file.name.lastIndexOf('.') >= 0 ? file.name.slice(file.name.lastIndexOf('.') + 1) : ''

    const {
      data: { signedUrl, uri }
    } = await post('/sign-url', {
      prefix: 'photos',
      contentType: file.type,
      ext
    })

    await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    })

    const pointId = store.getState().point.id
    const userId = store.getState().user.id

    const { data: point } = await post(`/points/${pointId}/photos`, {
      userId,
      uri
    })

    incRefreshVersion()

    store.setState({ point, isUploadingPhoto: false })
    return point
  } catch (error) {
    store.setState({ error, isUploadingPhoto: false })
  }
}

export const uploadPhotoToPoint = async ({ pointId, file }) => {
  try {
    store.setState({ isUploadingPhoto: true })

    const ext =
      file.name.lastIndexOf('.') >= 0 ? file.name.slice(file.name.lastIndexOf('.') + 1) : ''

    const {
      data: { signedUrl, uri }
    } = await post('/sign-url', {
      prefix: 'photos',
      contentType: file.type,
      ext
    })

    await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    })

    const userId = store.getState().user.id

    const { data: point } = await post(`/points/${pointId}/photos`, {
      userId,
      uri
    })

    store.setState({ isUploadingPhoto: false })

    incRefreshVersion()

    return point
  } catch (error) {
    store.setState({ error, isUploadingPhoto: false })
  }
}

export const justUploadPhoto = async ({ file }) => {
  try {
    store.setState({ isUploadingPhoto: true })

    const ext =
      file.name.lastIndexOf('.') >= 0 ? file.name.slice(file.name.lastIndexOf('.') + 1) : ''

    const {
      data: { signedUrl, uri }
    } = await post('/sign-url', {
      prefix: 'photos',
      contentType: file.type,
      ext
    })

    await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    })

    store.setState({ isUploadingPhoto: false })
    return uri
  } catch (error) {
    store.setState({ error, isUploadingPhoto: false })
  }
}

export const selectPhoto = async (pointId, photoId, selected) => {
  const currentPoint = store.getState().point
  if (currentPoint && currentPoint.id === pointId) {
    store.setState({
      point: {
        ...currentPoint,
        photos: currentPoint.photos.map((photo) =>
          photo.id === photoId
            ? {
                ...photo,
                selected
              }
            : photo
        )
      }
    })
  }
  const { data: point } = await patch(`/points/${pointId}/photos/${photoId}`, { selected })
  store.setState({ point })
  incRefreshVersion()
}

export const deletePhoto = async (photoId) => {
  try {
    store.setState({ isDeletingPhoto: true })
    const pointId = store.getState().point.id
    const { data: point } = await del(`/points/${pointId}/photos/${photoId}`)
    store.setState({
      point,
      isDeletingPhoto: false
    })
    incRefreshVersion()
    return point
  } catch (error) {
    store.setState({ error, isDeletingPhoto: false })
  }
}
