import { Fragment, h } from 'preact'
import { connect } from 'unistore/preact'
import { useEffect, useState } from 'preact/hooks'
import flatten from 'geojson-flatten'
import { BasicDialog, FileInput, NativeSelect, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { createLayer, setError } from '../../../actions'
import { readShapeAsGeoJson } from '../read-shape-as-geojson'

const formatOptions = [
  { value: 'geojson', label: 'GeoJSON' },
  { value: 'shp', label: 'ESRI Shapefile' }
]

const readGeoJson = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(JSON.parse(reader.result))
    reader.onerror = (err) => reject(err)
    reader.readAsText(file)
  })

const CreateLayer = ({ project, isUpdatingProject }) => {
  const [name, setName] = useState('')
  const [format, setFormat] = useState('geojson')
  const [geoJsonFile, setGeoJsonFile] = useState()
  const [shpFile, setShpFile] = useState()
  const [dbfFile, setDbfFile] = useState()
  const [prjFile, setPrjFile] = useState()

  useEffect(() => {
    if (geoJsonFile && !name) {
      setName(geoJsonFile.name.substring(0, geoJsonFile.name.indexOf('.')))
      console.log(geoJsonFile.name)
    }
  }, [geoJsonFile, name])

  const onClose = () => goBack(`/projects/${project.id}/layers`)

  const handleSubmit = async () => {
    try {
      let geojson
      if (format === 'shp') {
        geojson = await readShapeAsGeoJson(shpFile, dbfFile, prjFile)
      } else {
        geojson = await readGeoJson(geoJsonFile)
      }
      geojson = flatten(geojson)
      if (await createLayer({ name, geojson })) {
        onClose()
      }
    } catch (err) {
      setError(err)
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      title="Nytt kartlager"
      primaryActionText="Lägg till"
      primaryActionLoading={isUpdatingProject}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <SpacerVertical />
      <NativeSelect
        width="100%"
        label="Format"
        options={formatOptions}
        value={format}
        onChange={setFormat}
      />
      <SpacerVertical />
      {format === 'geojson' && (
        <FileInput label="Välj fil (.geojson)" value={geoJsonFile} onChange={setGeoJsonFile} />
      )}
      {format === 'shp' && (
        <Fragment>
          <FileInput label="Välj fil (.shp)" value={shpFile} onChange={setShpFile} />
          <SpacerVertical />
          <FileInput label="Välj fil (.prj)" value={prjFile} onChange={setPrjFile} />
          <SpacerVertical />
          <FileInput label="Välj fil (.dbf)" value={dbfFile} onChange={setDbfFile} />
        </Fragment>
      )}
      <SpacerVertical />
      <TextField
        width="100%"
        label="Namn"
        placeholder="Ange lagrets namn"
        value={name}
        onChange={setName}
      />
      <SpacerVertical />
    </BasicDialog>
  )
}

export default connect('project, isUpdatingProject')(CreateLayer)
