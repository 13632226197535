import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'
import { connect } from 'unistore/preact'
import {
  AddIcon,
  BackArrowIcon,
  Button,
  Divider,
  IconButton,
  ProgressCircular,
  SettingsIcon,
  SpacerHorizontal,
  SpacerVertical,
  ViewListIcon,
  List,
  ListItem,
  DeleteIcon,
  MoreIcon,
  DropdownMenu,
  DownloadIcon,
  ContentCopyIcon,
  PlaceIcon,
  DataTable,
  CheckmarkIcon
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo, Switch } from '@sodra/prutt'

import { fetchProject, setError, uploadPhotoToPoint } from '../../actions'
import { useLocalStorageState } from '../../use-local-storage-state'
import { copyToClipboard } from '../../copy-to-clipboard'
import { formatDuration } from '../../format-duration'
import { get, patch } from '../../api'

import NewPointWizard from './NewPointWizard'
import Members from './Members'
import Settings from './Settings'
import Attributes from './Attributes'
import Point from './Point'
import Download from './Download'
import Clone from './Clone'
import ProjectMap from './ProjectMap'
import DeletePoint from './DeletePoint'
import DeleteProject from './DeleteProject'
import PointPreviewOnDesktop from './PointPreviewOnDesktop'
import PointPreviewOnMobile from './PointPreviewOnMobile'
import Layers from './Layers'
import { fire } from '../../events'

const Project = ({ user, projectId, project, userLocation, refreshVersion, isNarrow }) => {
  const [lng, setLng] = useState()
  const [lat, setLat] = useState()
  const [zoom, setZoom] = useState()

  const [mode, setMode] = useLocalStorageState('project:mode', 'map')
  const [menu, setMenu] = useState()

  const [points, setPoints] = useState([])
  const [isFetchingPoint, setIsFetchingPoint] = useState(false)
  const [isFetchingPoints, setIsFetchingPoints] = useState(false)

  const [selectedPointId, setSelectedPointId] = useState()
  const [selectedPoint, setSelectedPoint] = useState()

  useEffect(() => {
    if (selectedPointId) {
      setIsFetchingPoint(true)
      get(`/points/${selectedPointId}`)
        .then(({ data: point }) => setSelectedPoint(point))
        .finally(() => setIsFetchingPoint(false))
    } else {
      setSelectedPoint(undefined)
    }
  }, [selectedPointId, refreshVersion])

  const onFileUpload = async (file) => {
    const point = await uploadPhotoToPoint({ pointId: selectedPoint.id, file })
    setSelectedPoint(point)
  }

  useEffect(() => {
    fetchProject(projectId)
  }, [projectId])

  useEffect(() => {
    if (project) {
      fire('layerschanged', project.layers)
    }
  }, [project])

  useEffect(() => {
    console.log('fetch points')
    let cancelled = false
    setIsFetchingPoints(true)
    get('/points', { projectId })
      .then(({ data: points }) => {
        if (!cancelled) {
          setPoints(points)
          if (points.length > 0) {
            let lat = 0
            let lng = 0
            for (let point of points) {
              lat += point.lat
              lng += point.lng
            }
            lat /= points.length
            lng /= points.length
            setLat(lat)
            setLng(lng)
            setZoom(17)
          }
        }
      })
      .catch((err) => {
        if (!cancelled) {
          setError(err)
        }
      })
      .finally(() => {
        if (!cancelled) {
          setIsFetchingPoints(false)
        }
      })
    return () => (cancelled = true)
  }, [projectId, refreshVersion])

  const closeMenu = () => setMenu()

  const handleLocationChange = ({ lng, lat, zoom }) => {
    setLng(lng)
    setLat(lat)
    setZoom(zoom)
  }

  const toggleSelectedPhoto = async (point, photoId) => {
    const photo = point.photos.find((photo) => photo.id === photoId)
    if (photo) {
      photo.selected = !photo.selected
      setPoints((points) => points.map((p) => (p.id === point.id ? point : p)))
      await patch(`/points/${point.id}/photos/${photo.id}`, { selected: photo.selected })
    }
  }

  if (!project || project.id !== projectId) {
    return (
      <Col
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        overflow="hidden"
        background="var(--surface)"
        zIndex="2"
        justifyContent="center"
        alignItems="center"
      >
        <ProgressCircular />
      </Col>
    )
  }

  if (
    !user.isAdmin &&
    project.private &&
    project.members.every((member) => member.id !== user.id)
  ) {
    goBack('/projects')
  }

  const geoJsonUrl = `${process.env.API_URL}/v1/projects/${projectId}/${project.name.replace(
    /\s+/g,
    '_'
  )}.geojson`

  const kmlUrl = `${process.env.API_URL}/v1/projects/${projectId}/${project.name.replace(
    /\s+/g,
    '_'
  )}.kml`

  const header = []
  for (let { name, type, numDecimals } of project.attributes) {
    let style
    if (type === 'number') {
      style = { monospaced: true, textAlign: 'right' }
    } else if (type === 'boolean') {
      style = { textAlign: 'center' }
    } else {
      style = { textAlign: 'left' }
    }
    header.push({ label: name, style })
  }
  header.push({ label: 'Foton' })
  header.push({ label: 'Skapad', style: { whiteSpace: 'nowrap' } })
  header.push({ label: 'Skapad av', style: { whiteSpace: 'nowrap' } })
  header.push({ label: '', style: { width: '40px' } })

  const body = []
  for (let point of points) {
    const row = []
    for (let { id, type, numDecimals } of project.attributes) {
      const attribute = point.attributes.find((a) => a.id === id)
      const value = attribute ? attribute.value : ''
      if (type === 'number') {
        row.push(value.trim() === '' ? '' : parseFloat(value).toFixed(numDecimals))
      } else if (type === 'boolean') {
        row.push(value === 'TRUE' ? 'Ja' : 'Nej')
      } else {
        row.push(value)
      }
    }
    row.push(
      <Row>
        {point.photos.slice(0, 5).map(({ id, uri, selected }) => {
          const src = `${process.env.CROPPER_URL}?url=${uri}&width=200&height=200&fit=cover`
          return (
            <Block
              position="relative"
              backgroundColor="#eee"
              backgroundImage={`url(${src})`}
              backgroundSize="cover"
              width="40px"
              height="40px"
              marginRight="5px"
              props={{
                onClick: (e) => {
                  e.stopPropagation()
                  routeTo(`/projects/${projectId}/points/${point.id}/photos/${id}`)
                }
              }}
            >
              <Block
                position="absolute"
                bottom="0"
                right="0"
                props={{
                  onClick: (e) => {
                    e.stopPropagation()
                    toggleSelectedPhoto(point, id)
                  }
                }}
              >
                <CheckmarkIcon
                  position="absolute"
                  bottom="0"
                  right="0"
                  size="12"
                  background={selected ? 'var(--accent)' : 'var(--surface)'}
                  fill={selected ? 'var(--on-accent)' : 'var(--on-surface-lighter)'}
                />
              </Block>
            </Block>
          )
        })}
      </Row>
    )
    row.push(formatDuration(point.created))
    row.push(point.user.name)
    row.push(
      <IconButton
        icon={DeleteIcon}
        onClick={() => routeTo(`/projects/${project.id}/delete-point/${point.id}`)}
      />
    )
    body.push(row)
  }

  return (
    <Col
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      overflow="hidden"
      background="var(--surface)"
      zIndex="2"
    >
      <Row
        alignItems="center"
        padding="10px 20px"
        background="var(--surface)"
        zIndex="1"
        boxShadow="0 1px 3px var(--box-shadow-color)"
      >
        <IconButton icon={BackArrowIcon} onClick={() => goBack(`/projects`)} />
        <SpacerHorizontal />
        <Block
          flex="1"
          textAlign="center"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {project.name}
        </Block>
        <SpacerHorizontal />
        {mode === 'map' && (
          <Button
            small
            icon={AddIcon}
            onClick={() => routeTo(`/projects/${projectId}/create-point`)}
          >
            Ny punkt
          </Button>
        )}
        <IconButton
          icon={MoreIcon}
          onClick={({ type, target, x, y }) => {
            if (type === 'keyboard') {
              setMenu({ positionElement: target })
            } else {
              setMenu({ position: { x, y } })
            }
          }}
        />
      </Row>
      <Block flex="1" overflow="auto">
        {mode === 'map' && (
          <ProjectMap
            width="100%"
            height="100%"
            onClick={setSelectedPointId}
            onLocationChange={handleLocationChange}
          />
        )}
        {mode === 'list' && (
          <Block width="900px" maxWidth="calc(100% - 40px)" margin="auto">
            <SpacerVertical />
            <Block fontSize="24px">{project.name}</Block>
            <SpacerVertical />
            <Block color="var(--on-surface-light)">{project.description}</Block>
            <SpacerVertical />
            <Block>GeoJSON</Block>
            <Row alignItems="center">
              <Block overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                <a target="_blank" href={geoJsonUrl}>
                  {geoJsonUrl}
                </a>
              </Block>
              <SpacerHorizontal small />
              <IconButton
                flexShrink="0"
                icon={ContentCopyIcon}
                onClick={() => copyToClipboard(geoJsonUrl)}
              />
            </Row>
            <SpacerVertical />
            <Block>KML</Block>
            <Row alignItems="center">
              <Block overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                <a target="_blank" href={kmlUrl}>
                  {kmlUrl}
                </a>
              </Block>
              <SpacerHorizontal small />
              <IconButton
                flexShrink="0"
                icon={ContentCopyIcon}
                onClick={() => copyToClipboard(kmlUrl)}
              />
            </Row>
            <SpacerVertical large />
            <Row alignItems="center">
              <Block>Punkter</Block>
              <Block flex="1" />
              <Button
                small
                icon={AddIcon}
                onClick={() => routeTo(`/projects/${project.id}/create-point`)}
              >
                Ny punkt
              </Button>
            </Row>
            <SpacerVertical small />
            <DataTable
              width="100%"
              loading={isFetchingPoints}
              header={header}
              body={body}
              onClick={(index) => routeTo(`/projects/${project.id}/points/${points[index].id}`)}
            />
          </Block>
        )}
        {mode === 'map' && isNarrow && (
          <PointPreviewOnMobile point={selectedPoint} onFileUpload={onFileUpload} />
        )}
        {mode === 'map' && !isNarrow && (
          <PointPreviewOnDesktop point={selectedPoint} onFileUpload={onFileUpload} />
        )}
        {menu && (
          <DropdownMenu
            onClose={closeMenu}
            position={menu.position}
            positionElement={menu.positionElement}
          >
            <SpacerVertical tiny />
            <List autofocus delayClick>
              <ListItem
                icon={PlaceIcon}
                text="Visa karta"
                onClick={() => {
                  setMode('map')
                  closeMenu()
                }}
                disabled={mode === 'map'}
              />
              <ListItem
                icon={ViewListIcon}
                text="Visa tabell"
                onClick={() => {
                  setMode('list')
                  closeMenu()
                }}
                disabled={mode === 'list'}
              />
              <Divider />
              <ListItem
                icon={SettingsIcon}
                text="Inställningar"
                onClick={() => routeTo(`/projects/${project.id}/settings`)}
              />
              <ListItem
                icon={DownloadIcon}
                text="Ladda ner…"
                onClick={() => routeTo(`/projects/${project.id}/download`)}
              />
              <ListItem
                icon={ContentCopyIcon}
                text="Kopiera…"
                onClick={() => routeTo(`/projects/${project.id}/clone`)}
              />
              <Divider />
              <ListItem
                icon={DeleteIcon}
                text="Radera projekt"
                onClick={() => routeTo(`/projects/${project.id}/delete`)}
              />
            </List>
            <SpacerVertical tiny />
          </DropdownMenu>
        )}
        <SpacerVertical />
      </Block>
      <Switch>
        <Route path="/projects/:projectId/delete" component={DeleteProject} />
        <Route path="/projects/:projectId/download" component={Download} />
        <Route
          path="/projects/:projectId/create-point"
          render={() => (
            <NewPointWizard
              lat={userLocation ? userLocation.lat : lat}
              lng={userLocation ? userLocation.lng : lng}
              zoom={zoom}
            />
          )}
        />
        <Route path="/projects/:projectId/attributes" component={Attributes} />
        <Route path="/projects/:projectId/members" component={Members} />
        <Route path="/projects/:projectId/settings" component={Settings} />
        <Route path="/projects/:projectId/layers" component={Layers} />
        <Route path="/projects/:projectId/clone" component={Clone} />
        <Route path="/projects/:projectId/points/:pointId" component={Point} />
        <Route path="/projects/:projectId/delete-point/:pointId" component={DeletePoint} />
      </Switch>
    </Col>
  )
}

export default connect('user, project, userLocation, refreshVersion, isUploadingPhoto, isNarrow')(
  Project
)
