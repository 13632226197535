import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import {
  BasicDialog,
  Button,
  Form,
  Link,
  SpacerHorizontal,
  SpacerVertical,
  TextField
} from '@sodra/bongo-ui'
import { Block, Col, Row } from 'jsxstyle/preact'

import { clearError, login } from '../actions'

import logo from 'url:../logo.svg'

const NotLoggedIn = ({ isLoggingIn, error }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async () => {
    login({ email, password })
  }

  return (
    <Row
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      alignItems="center"
      justifyContent="center"
      background="var(--surface)"
    >
      <Col alignItems="center">
        <img src={logo} width="100" />
        <SpacerVertical large />
        <Form width="240px" onSubmit={handleSubmit}>
          <TextField width="100%" type="email" label="Epost" value={email} onInput={setEmail} />
          <SpacerVertical small />
          <TextField
            width="100%"
            type="password"
            label="Lösenord"
            value={password}
            onInput={setPassword}
          />
          <SpacerVertical small />
          <Button contained type="submit" width="100%" loading={isLoggingIn}>
            Logga in
          </Button>
        </Form>
        <SpacerVertical large />
        <Link to="/register">Skapa konto</Link>
        <SpacerVertical />
        <Link to="/forgot-password">Glömt lösenord?</Link>
      </Col>
      {error && (
        <BasicDialog
          title="Oops!"
          onClose={clearError}
          action1Text="Close"
          onAction1Click={clearError}
        >
          {error.message}
        </BasicDialog>
      )}
    </Row>
  )
}

export default connect('isLoggingIn, error')(NotLoggedIn)
