import { Fragment, h } from 'preact'
import { connect } from 'unistore/preact'
import { useEffect, useMemo, useState } from 'preact/hooks'
import { Row } from 'jsxstyle/preact'

import {
  BasicDialog,
  Button,
  Checkbox,
  ExpandMoreIcon,
  Form,
  Select,
  SpacerHorizontal,
  SpacerVertical,
  TextField
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { useLocalStorageState } from '../../use-local-storage-state'
import { download } from '../../actions'
import { get } from '../../api'
import ShowProps from './ShowProps'

export const getSelectionStartStop = (elem) => {
  const value = elem.value
  let start = elem.selectionStart
  while (
    --start > 0 &&
    value.charAt(start) !== ' ' &&
    value.charAt(start) !== '.' &&
    value.charAt(start) !== ',' &&
    value.charAt(start) !== '\n' &&
    value.charAt(start) !== '%'
  );
  if (value.charAt(start) === '%') {
    let stop = start + 1
    while (++stop < value.length && value.charAt(stop) !== ' ' && value.charAt(stop) !== '\n');
    return { start, stop }
  }
  return { start: -1, stop: -1 }
}

const byKeyLengthDesc = (a, b) => {
  if (a.key.length > b.key.length) {
    return -1
  }
  if (a.key.length < b.key.length) {
    return 1
  }
  return 0
}

const Download = ({ project, isDownloading, isNarrow }) => {
  const [format, setFormat] = useLocalStorageState('fotoapp:download:format', 'geojson')
  const [projection, setProjection] = useLocalStorageState('fotoapp:download:projection', 'wgs84')
  const [selectedImages, setSelectedImages] = useLocalStorageState(
    'fotoapp:download:selectedImages',
    true
  )
  const [maxSize, setMaxSize] = useLocalStorageState('fotoapp:download:maxSize', true)
  const [maxWidth, setMaxWidth] = useLocalStorageState('fotoapp:download:maxWidth', 1500)
  const [maxHeight, setMaxHeight] = useLocalStorageState('fotoapp:download:maxWidth', 1500)

  const [filenameTpl, setFilenameTpl] = useLocalStorageState(
    `fotoapp:${project.id}:download:filenameTpl`,
    '%PUNKT_ID%_foto_%FOTO_NR%'
  )
  const [elem, setElem] = useState()
  const [props, setProps] = useState([])
  const [suggest, setSuggest] = useState([])

  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    get(`/projects/${project.id}/props`).then(({ data: props }) => {
      setProps(props)
    })
  }, [project])

  const filenameExample = useMemo(() => {
    if (props.length > 0 && filenameTpl.includes('%')) {
      let filenameExample = filenameTpl + '.jpg'
      const sortedProps = props.slice(0).sort(byKeyLengthDesc)
      for (let { key, value } of sortedProps) {
        const regex = new RegExp('%' + key + '%', 'g')
        filenameExample = filenameExample.replace(regex, value)
      }
      return filenameExample
    }
  }, [filenameTpl, props])

  const handleFilenamTpl = (filename, e) => {
    setFilenameTpl(filename)
    const { start, stop } = getSelectionStartStop(e.target)
    if (start >= 0 && stop >= 0) {
      const prefix = filename.slice(start + 1, stop)
      setSuggest(
        props
          .filter(({ key }) => key.startsWith(prefix))
          .map(({ key, value, source }) => {
            let text = key
            if (source !== 'predefined' && source !== 'point-attribute') {
              text += ' (' + source + ')'
            }
            return {
              text,
              secondaryText: value.length > 50 ? value.slice(0, 50) + '…' : value
            }
          })
      )
    } else {
      setSuggest([])
    }
  }

  const handleSuggestSelect = ({ text }) => {
    const { start, stop } = getSelectionStartStop(elem)
    setFilenameTpl(
      (filenameTpl) => filenameTpl.slice(0, start) + '%' + text + '%' + filenameTpl.slice(stop)
    )
    setTimeout(() => {
      elem.selectionStart = start + text.length + 1
      elem.selectionEnd = start + text.length + 1
    }, 0)
    setSuggest([])
  }

  const handleSuggestClose = () => {
    setSuggest([])
  }

  const handleSubmit = async () => {
    // const filename =
    //   format === 'zip' || format === 'shapefile' ? `${project.name}.zip` : `${project.name}.geojson`
    let filename
    if (format === 'zip' || format === 'shapefile'){
      filename = `${project.name}.zip` 
    }else if (format === 'geojson'){
      filename = `${project.name}.geojson` 
    }else if (format === 'csv'){
      filename = `${project.name}.csv` 
    }
    await download(filename, {
      projectId: project.id,
      format,
      projection,
      selectedImages,
      filenameTpl,
      maxWidth: maxSize ? maxWidth : undefined,
      maxHeight: maxSize ? maxHeight : undefined
    })
  }

  const onClose = () => goBack(`/projects/${project.id}`)

  return (
    <BasicDialog
      full={isNarrow}
      disableAutofocus
      title="Ladda ner"
      primaryActionText="Ladda ner"
      primaryActionLoading={isDownloading}
      onPrimaryActionClick={handleSubmit}
      action1Text="Stäng"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <SpacerVertical small />
      <Form onSubmit={handleSubmit}>
        <Select
          width="100%"
          label="Format"
          options={[
            { value: 'geojson', text: 'GeoJSON' },
            { value: 'shapefile', text: 'Shapefile' },
            { value: 'csv', text: 'CSV' },
            { value: 'zip', text: 'Foton' }
          ]}
          value={format}
          onChange={setFormat}
        />
        <SpacerVertical small />
        {format === 'zip' && (
          <Fragment>
            <Row alignItems="center">
              <Checkbox checked={maxSize} onChange={setMaxSize} />
              <SpacerHorizontal />
              <Row alignItems="center" opacity={maxSize ? '100%' : '20%'}>
                <TextField
                  flex="1"
                  type="number"
                  label="Max bredd"
                  value={maxWidth}
                  onInput={setMaxWidth}
                  disabled={!maxSize}
                />
                <SpacerHorizontal small />
                <TextField
                  flex="1"
                  type="number"
                  label="Max höjd"
                  value={maxHeight}
                  onInput={setMaxHeight}
                  disabled={!maxSize}
                />
              </Row>
            </Row>
            <SpacerVertical small />
            <Checkbox
              label="Endast vald för rapport"
              checked={selectedImages}
              onChange={setSelectedImages}
            />
            <SpacerVertical small />
            {!showMore && (
              <Button icon={ExpandMoreIcon} onClick={() => setShowMore(true)}>
                Mer
              </Button>
            )}
            {showMore && (
              <Fragment>
                <TextField
                  autocomplete="off"
                  width="100%"
                  label="Filnamn"
                  value={filenameTpl}
                  onInput={handleFilenamTpl}
                  suggestMode="at-caret"
                  suggest={suggest}
                  onSuggestSelect={handleSuggestSelect}
                  onSuggestClose={handleSuggestClose}
                  onFocus={(e) => setElem(e.target)}
                  infoText={filenameExample}
                />
                <SpacerVertical small />
                <Button outlined onClick={() => routeTo(`/projects/${project.id}/download/props`)}>
                  Infoga attribut
                </Button>
              </Fragment>
            )}
          </Fragment>
        )}
        {(format === 'geojson' || format === 'shapefile' || format === 'csv') && (
          <Fragment>
            <Select
              width="100%"
              label="Referenssystem"
              options={[
                { value: 'wgs84', text: 'WGS 84 (EPSG:4326)' },
                { value: 'sweref99', text: 'SWEREF 99 (EPSG:3006)' },
                { value: 'rt90', text: 'RT 90 (EPSG:3021)' }
              ]}
              value={projection}
              onChange={setProjection}
            />
            <SpacerVertical small />
            <Checkbox
              label="Endast vald för rapport"
              checked={selectedImages}
              onChange={setSelectedImages}
            />
          </Fragment>
        )}
        <SpacerVertical small />
      </Form>
      <Route
        path="/projects/:projectId/download/props"
        render={() => (
          <ShowProps
            props={props}
            onSelect={(key) => {
              setFilenameTpl((filenameTpl) => filenameTpl + '%' + key + '%')
              goBack(`/projects/${project.id}/download`)
            }}
          />
        )}
      />
    </BasicDialog>
  )
}

export default connect('project, isDownloading, isNarrow')(Download)
