import { h } from 'preact'
import { connect } from 'unistore/preact'
import { BasicDialog } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { deleteAttribute } from '../../../actions'

const DeleteAttribute = ({ project, attributeId, isUpdatingProject }) => {
  const attribute = project.attributes.find((attribute) => attribute.id === attributeId)

  const handleSubmit = async () => {
    if (await deleteAttribute(attribute.id)) {
      onClose()
    }
  }

  const onClose = () => goBack(`/projects/${project.id}/attributes`)

  if (!attribute) {
    return null
  }

  return (
    <BasicDialog
      disableAutofocus
      title="Ta bort attribut"
      primaryActionText="Ta bort"
      primaryActionLoading={isUpdatingProject}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      Vill du ta bort <strong>{attribute.name}</strong>?
    </BasicDialog>
  )
}

export default connect('project, isUpdatingProject')(DeleteAttribute)
