import { h } from 'preact'
import { useState } from 'preact/hooks'
import { connect } from 'unistore/preact'
import { BasicDialog, Form, Select, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'
import { updateAttribute } from '../../../actions'

import { typeOptions } from '../../attributes'
const EditAttribute = ({ project, attributeId, isUpdatingProject }) => {
  const attribute = project.attributes.find((attribute) => attribute.id === attributeId)

  const [name, setName] = useState(attribute.name)
  const [nameError, setNameError] = useState('')
  const [type, setType] = useState(attribute.type)
  const [typeError, setTypeError] = useState('')
  const [numDecimals, setNumDecimals] = useState(attribute.numDecimals)
  const [numDecimalsError, setNumDecimalsError] = useState('')

  const handleSubmit = async () => {
    if (name.trim() === '') {
      setNameError('Du måste ange ett namn')
      return
    }

    if (await updateAttribute(attribute.id, { name, type, numDecimals })) {
      onClose()
    }
  }

  const onClose = () => goBack(`/projects/${project.id}/attributes`)

  return (
    <BasicDialog
      disableAutofocus
      title="Ändra attribut"
      primaryActionText="Spara"
      primaryActionLoading={isUpdatingProject}
      onPrimaryActionClick={handleSubmit}
      action1Text="Avbryt"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <TextField width="100%" label="Namn" value={name} onInput={setName} errorText={nameError} />
        <SpacerVertical />
        <Select width="100%" label="Typ" options={typeOptions} value={type} onChange={setType} />
        <SpacerVertical />
        {type === 'number' && (
          <TextField
            width="100%"
            type="number"
            label="Antal decimaler"
            value={numDecimals}
            onInput={setNumDecimals}
          />
        )}
      </Form>
      <SpacerVertical />
    </BasicDialog>
  )
}

export default connect('project, isUpdatingProject')(EditAttribute)
